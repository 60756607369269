import { createTheme } from "@mui/material/styles";

import { amber, blue, grey, lightBlue, lime, red } from "@mui/material/colors";

export const theme = createTheme({
  shape: {
    borderRadius: 7,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
        outlined: {
          border: "1px solid #ececec",
          "&:hover": { border: "1px solid #b7b7b7" },
        },
      },
    },
  },
  palette: {
    background: {
      default: "#f8f9fa",
    },
    primary: {
      light: "#787A91",
      main: "#1E283D",
      dark: "#4A5263",
    },
    secondary: {
      main: "#1E283D",
    },
    neutral: {
      main: "#787A91",
    },
    badge: {
      main: "#FF7F3F",
    },
    warning: {
      main: "#ff3d00",
    },
    aptitudeTestTag: {
      main: lime[700],
      contrastText: lime[900],
    },

    likertYBtn: {
      light: "white",
      main: "#748b9c",
      dark: "#8ca4b7",
    },
    likertNBtn: {
      light: "white",
      main: "#79869d",
      dark: "#8795ae",
    },

    contrastBtn: {
      main: "black",
      contrastText: "#637381",
    },
    personalityTestTag: {
      main: amber[400],
      contrastText: amber[900],
    },
    preExamY: {
      main: lightBlue[500],
      contrastText: lightBlue[50],
    },
    preExamN: {
      main: red[500],
      contrastText: red[100],
    },
    subtitle: {
      main: grey[500],
      contrastText: grey[700],
    },
    layoutBtn: {
      main: grey[50],
      contrastText: grey[900],
    },

    layoutEditBtn: {
      main: "white",
      dark: grey[400],
    },

    templateDownloadBtn: {
      light: "#757ce8",
      main: "#FFD7D7",
      dark: "#f2b3b3",
    },

    templateUploadBtn: {
      light: "#757ce8",
      main: "#C9D1FF",
      dark: "#a7b4fa",
    },

    sortByGenBtn: {
      light: "#eee9f7",
      main: "#eef2f5",
      dark: "#dee1e3",
    },

    moreBtn: {
      light: "#757ce8",
      main: "#f7fbff",
      dark: "#e8f2fc",
    },
    deleteBtn: {
      main: "#f87373a3",
      dark: "#fc2d2da3",
    },
    addBtn: {
      main: "#a6cdf3",
      dark: "#c2d9ee",
    },
    addExList: {
      main: "#d3e1f1",
      dark: "#85afe0",
    },

    restoreButton: {
      main: "#08b41a",
      dark: "#08b41a",
    },
    removeButton: {
      main: "#fcf0ed",
      dark: "#fae2dc",
    },
  },
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 10,
        border: "1px solid #757472",
        borderRadius: "7px",
        height: "200px",
        overflowY: "hidden",
      },
      editor: {
        borderTop: "1px solid ##bdbbbb",
      },
      editorContainer: {
        padding: "10px",
        height: "200px",
        width: "100%",
        overflowY: "scroll",
      },
    },
  },
});

export const tableHeader = {
  "& .MuiTableHead-root > tr > th ": {
    background: "#fdfdfd",
    color: "#585757",
    fontWeight: 700,
    fontSize: "0.9rem",
    height: 43,
  },
};

export const tableWrapper = {
  overflowX: "auto",
  height: "75%",
  // padding: isMobile ? 0.5 : 0,
  borderRadius: 2,
  border: "1px solid #efefefb6",
  boxShadow: "5px 5px 60px #f0f0f0, -5px -5px 10px #ffffff",
};

export const containerBoxTheme = {
  borderRadius: 2,
  background: "#ffffff",
  boxShadow: "7px 7px 14px #d1d1d1",
  width: "100%",
  height: "100%",
};

export const iconBtnTheme = {
  background: "transparent",
  borderRadius: 2,
  color: "#848faa",
  "&:hover": {
    transition: "all 0.5s",
    color: "#32436d",
    // boxShadow: "5px 5px 15px #d1d0d0, -5px -5px 15px #ffffff",
  },
};
