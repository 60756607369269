import React, { Suspense, useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { Link, useLocation, useParams } from "react-router-dom";

import { useSetRecoilState } from "recoil";
import { questionPageDetailAtom, questionsDetailAtom } from "recoil/store";

import useGetQuery from "hooks/useGetQuery";

import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { practiceType } from "utils/practiceType";
import CreateQuestionModal from "../CreateQuestionModal";
import QuestionAptitude from "./aptitude/QuestionAptitude";
import QuestionPersonal from "./personal/QuestionPersonal";
import { Button, Stack } from "@mui/material";
import ExcelUploadModal from "../excel/ExcelUploadModal";
import useGetFile from "hooks/useGetFile";
import Swal from "sweetalert2";
import { confirmModalConfig } from "components/templates/modalConfig";
import { questionIcon } from "assets/icon/Icon_modal";
import TopTitle from "components/templates/topTitle/TopTitle";
import { Plus } from "lucide-react";

let maxTabIndex = 1;
let currentTablIndex = 0;

function TabPanel(props) {
  const { children, tabId } = props;
  return (
    <Box
      value={maxTabIndex}
      index={maxTabIndex}
      key={maxTabIndex}
      height="100%"
    >
      {children}
    </Box>
  );
}

const QuestionDetail = () => {
  const { likertYN, ipsativeYN, maxScale } = useLocation().state.item;

  const question1st = useSetRecoilState(questionsDetailAtom);
  const question2nd = useSetRecoilState(questionPageDetailAtom);

  const [tabId, setTabId] = useState(0);
  const [tabs, setAddTab] = useState([<Tab value={0} key={0} />]);

  const [createQuestionModalOn, setCreateQuestionModalOn] = useState(false);
  const [excelUploadModalOn, setExcelUploadModalOn] = useState(false);

  const handleCreateQuestionModal = () =>
    setCreateQuestionModalOn(!createQuestionModalOn);

  const handleUploadExcelModal = () =>
    setExcelUploadModalOn(!excelUploadModalOn);

  const { id } = useParams();

  //* 1차정보 요청 QUERY
  const { data, isLoading, isFetching, isError, refetch } = useGetQuery(
    "questionDetail1",
    { questionIdx: Number(id) },
    true,
    false
  );

  //* 2차정보 요청 PARAM
  const [getQnAparamValues, setGetQnAParamValues] = useState({
    questionIdx: Number(id),
    queryValue: {
      likertYN: likertYN,
      ipsativeYN: ipsativeYN,
      pageNo: tabId + 1,
      maxScale: maxScale,
    },
  });

  //* 2차정보 요청 QUERY
  const {
    data: getQuestionQnA,
    isLoading: getPracticeQnA_isLoading,
    isFetching: getPracticeQnA_isFetching,
  } = useGetQuery("questionDetail2", getQnAparamValues, true, false);

  useEffect(() => {
    if (data) {
      setGetQnAParamValues((prev) => ({
        ...prev,
        questionIdx: Number(id),
        queryValue: {
          likertYN: data.likertYN,
          ipsativeYN: data.ipsativeYN,
          pageNo: tabId + 1,
          maxScale: data.maxScale,
        },
      }));
    }
  }, [tabId]);

  useEffect(() => {
    if (!getPracticeQnA_isLoading && !getPracticeQnA_isFetching) {
      question2nd(getQuestionQnA);
    }
  }, [getQuestionQnA, getPracticeQnA_isLoading, getPracticeQnA_isFetching]);

  const handleTabChange = (_, newTabId) => {
    if (newTabId === "tabProperties") {
      handleAddTab();
    } else {
      currentTablIndex = newTabId;
      setTabId(newTabId);
    }
  };

  const handleAddTab = () => {
    setAddTab([
      ...tabs,
      <Tab label={`${tabs.length + 1}`} key={tabs.length + 1} />,
    ]);
    setTabId(tabs.length);
    currentTablIndex = tabs.length;
  };

  //* tab Render
  const tabRender = (data) => {
    //* 처음부터 등록된 문항이 하나도 없을때
    if (data.pageMaxNo) {
      return [...Array(parseInt(data.pageMaxNo))].map((n, index) => {
        return <Tab key={index} label={index + 1} value={index} />;
      });
    }
  };

  const [downloadTemplate, setDownloadTemplate] = useState(false);

  //* 문항 업로드 템플릿 다운로드
  const { data: downloadReport_query } = useGetFile(
    "SUPER",
    "questionTemplate",
    null,
    downloadTemplate,
    setDownloadTemplate
  );

  const downloadQuestionTemplate = () => {
    Swal.fire(
      confirmModalConfig({
        title: "템플릿 다운로드",
        title: "템플릿을 다운로드 하시겠습니까?",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        setDownloadTemplate(!downloadTemplate);
      }
    });
  };

  useEffect(() => {
    data && setAddTab(tabRender(data));
    data && question1st(data);
  }, [data]);

  const practiceRender = (data) => {
    if (practiceType(data) === "APTITUDE") {
      return <QuestionAptitude tabId={tabId} />;
    } else {
      return <QuestionPersonal tabId={tabId} />;
    }
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={2} height="100%">
      <TopTitle
        list={[
          {
            title: "문항 관리",
            url: `/question`,
            current: false,
          },
          {
            title: `${data?.questionName}`,
            url: `/question/${id}`,
            current: true,
          },
        ]}
      />

      <Box
        bgcolor={"white"}
        borderRadius={2}
        p={1.5}
        py={2}
        display="flex"
        flexDirection="column"
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Box maxWidth={"80%"}>
            {data && data?.pageInfoList?.length < 1 ? null : (
              <Tabs
                variant="scrollable"
                value={tabId}
                onChange={handleTabChange}
                TabIndicatorProps={{ sx: { display: "none" } }}
                sx={tabStyle}
              >
                {tabs?.map((child) => child)}
                <Tab
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    "&>.MuiTab-iconWrapper": { margin: 0 },
                  }}
                  icon={<AddRoundedIcon />}
                  value="tabProperties"
                  label="페이지 추가"
                />
              </Tabs>
            )}
          </Box>
          <Box>
            <Stack direction="row" columnGap={1}>
              <Tooltip title="문항 추가" arrow placement="top">
                <Box component="span">
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleCreateQuestionModal}
                    startIcon={<Plus size={15} />}
                  >
                    문항 추가
                  </Button>
                </Box>
              </Tooltip>
            </Stack>
          </Box>
        </Stack>

        <Grid height={"100%"}>
          <TabPanel tabId={tabId}>{data && practiceRender(data)}</TabPanel>
        </Grid>

        <Dialog
          maxWidth={data && practiceType(data) === "APTITUDE" ? "md" : "md"}
          fullWidth
          open={createQuestionModalOn}
          onClose={handleCreateQuestionModal}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <CreateQuestionModal
              pageNo={tabId + 1}
              data={data}
              onClose={handleCreateQuestionModal}
            />
          </Suspense>
        </Dialog>

        <Dialog
          maxWidth={"xs"}
          fullWidth
          open={excelUploadModalOn}
          onClose={handleUploadExcelModal}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <ExcelUploadModal
              pageNo={tabId + 1}
              data={data}
              onClose={handleUploadExcelModal}
            />
          </Suspense>
        </Dialog>
      </Box>
    </Box>
  );
};

const tabStyle = {
  "&": {
    minHeight: "auto",
  },
  "& > .MuiTabs-scroller > .MuiTabs-flexContainer": {
    columnGap: 1,
  },
  "& > .MuiTabs-scroller > div> button": {
    minWidth: 8,
    minHeight: 8,
    border: "1px solid #777",
    borderRadius: 1,
    fontWeight: 700,
    px: 1.2,
    py: 0.5,
  },
  "& > .MuiTabs-scroller > div> .Mui-selected": {
    minWidth: 12,
    minHeight: 12,
    color: "#fff",
    background: "#1E283D",
    fontWeight: 700,
    border: "1px solid #1E283D",
  },

  "& > .MuiTabScrollButton-root.Mui-disabled": {
    display: "none",
  },
};

export default QuestionDetail;
