import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";

import { focusManager } from "@tanstack/react-query";
import useGetQueryhr from "hooks/useGetQueryhr";

import { tableHeader } from "themes/theme";
import { dateFormatTime } from "utils/formatter/dateFormat";
import { ReactComponent as NoVideo } from "assets/icon/videoPlaceholder.svg";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { VIDEO_LOG_DATA } from "enums/tableColumn";

const TesterVideoLogModal = ({ roomTesterInfo, onClose, pilotFlag = false }) => {
  // SCREEN, CAM, MOBILE
  console.log("roomTesterInfo: ", roomTesterInfo);
  const [tabValue, setTabValue] = useState("ALL");
  const [paramValues, setParamValues] = useState({
    testerIdx: roomTesterInfo.testerIdx,
    page: { pageNo: 1 },

    // input: defaultFilterValues,
  });

  const { data, pageInfo, isLoading, isFetching, isError, refetch } = useGetQueryhr(
    "getTesterVideoData",
    paramValues,
    true,
    pilotFlag
  );

  const handleChange = (_, newValue) => {
    setTabValue(newValue);

    setParamValues((prev) => ({
      ...prev,
      recordType: newValue,
      page: { pageNo: 1 },
    }));
  };

  const [videoSrc, setVideoSrc] = useState({ recordUrl: "" });

  const handleSelectRow = (_, row) => {
    setVideoSrc(row);
  };

  const handleDownload = async (_, src) => {
    const videoFileName = `${roomTesterInfo.testerName}_${roomTesterInfo.testerCell}_${src.fileName}.mp4`;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", src.recordUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.target = "_blank";
      tag.download = videoFileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.onerror = (err) => {
      alert("Failed to download picture");
    };
    xhr.send();
  };

  useEffect(() => {
    focusManager.setFocused(false);
    return () => focusManager.setFocused(undefined);
  }, []);

  return (
    <>
      <DialogTitle
        variant="h5"
        fontWeight="bold"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        응시자 비디오로그
        <Typography component="span" fontWeight={700}>
          {`성명 : ${roomTesterInfo.testerName}`}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <Box
          sx={{
            height: data?.data.length < 1 ? "auto" : "100%",
            overflowY: "auto",
          }}
        >
          {data && data?.data.length < 1 ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              // height="100%"
            >
              <EmptyPlaceholder />
            </Grid>
          ) : (
            <>
              <Tabs value={tabValue} onChange={handleChange}>
                <Tab label="전체" key={0} value={"ALL"} />
                <Tab label="SCREEN" key={1} value={"SCREEN"} />
                <Tab label="CAM" key={2} value={"CAM"} />
                <Tab label="MOBILE" key={3} value={"MOBILE"} />
              </Tabs>

              <Grid container gap={4} alignItems="center">
                <Grid item xs>
                  {isFetching || isLoading ? (
                    <LoadingSkeleton />
                  ) : (
                    <>
                      <Paper
                        sx={{
                          overflowX: "auto",
                        }}
                        variant="outlined"
                      >
                        <Table stickyHeader sx={tableHeader} size="small">
                          <TableHead>
                            <TableRow>
                              {VIDEO_LOG_DATA.map((item) => (
                                <TableCell
                                  key={item.label}
                                  sx={{ width: item.size, fontWeight: "bold" }}
                                  align="center"
                                >
                                  {item.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data &&
                              data.data.map((row, index) => (
                                <TableRow
                                  hover
                                  key={index}
                                  sx={{
                                    "&": {
                                      height: 43,
                                      background: row === videoSrc ? "#DDDDDD" : null,
                                    },
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  tabIndex={-1}
                                  onClick={(e) => handleSelectRow(e, row)}
                                >
                                  <TableCell padding="none" align="center" component="th" scope="row">
                                    {row.recordType}
                                  </TableCell>
                                  <TableCell padding="none" align="center">
                                    {dateFormatTime(row.recordAt)}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Paper>
                      <Pagination
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 2,
                        }}
                        count={pageInfo.totalPage}
                        page={pageInfo.pageNo}
                        onChange={(_, page) => {
                          setParamValues((prev) => ({
                            ...prev,
                            page: { pageNo: page },
                          }));
                        }}
                      />
                    </>
                  )}
                </Grid>

                {videoSrc.recordUrl !== "" ? (
                  <Grid item xs container direction="column" alignItems={"end"} rowGap={1}>
                    <Grid item>
                      <CardMedia src={videoSrc.recordUrl} component="video" controls />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent="flex-end" alignItems={"center"}>
                      <Grid item container justifyContent={"flex-start"} alignItems="center" rowGap={1}>
                        <Grid item container alignItems={"center"} columnGap={1} justifyContent="flex-end">
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ "&": { fontWeight: 700 } }}
                            onClick={(e) => handleDownload(e, videoSrc)}
                          >
                            다운로드
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs container justifyContent="center">
                    <NoVideo
                      style={{
                        width: "100%",
                        height: "auto",
                        color: "grey",
                        padding: 20,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: 3, pl: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </>
  );
};
export default TesterVideoLogModal;
