import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Search } from "lucide-react";

const SearchCompany = ({ submitSearch, searchRef }) => {
  return (
    <Grid container columnGap={1}>
      <TextField
        select
        label="검색내용"
        size="small"
        name="search"
        value={"companyName"}
        children={[
          <MenuItem key={"1"} value={"companyName"}>
            회사명
          </MenuItem>,
        ]}
      />
      <TextField
        size="small"
        inputRef={searchRef}
        sx={{ width: 300 }}
        placeholder={"미입력시 전체검색"}
      />
      <Button
        variant="outlined"
        startIcon={<Search size={15} />}
        onClick={submitSearch}
      >
        검색
      </Button>
    </Grid>
  );
};

export default SearchCompany;
