import template1 from "../assets/image/templates/01.png";
import template2 from "../assets/image/templates/02.png";
import template3 from "../assets/image/templates/03.png";
import template4 from "../assets/image/templates/04.png";
import template5 from "../assets/image/templates/05.png";
import template6 from "../assets/image/templates/06.png";
import template7 from "../assets/image/templates/07.png";
import template8 from "../assets/image/templates/08.png";

export const TEMPLATE_IMG = [
  { src: template1, name: "템플릿1" },
  { src: template2, name: "템플릿2" },
  { src: template3, name: "템플릿3" },
  { src: template4, name: "템플릿4" },
  { src: template5, name: "템플릿5" },
  { src: template6, name: "템플릿6" },
  { src: template7, name: "템플릿7" },
  { src: template8, name: "템플릿8" },
];
