// export const URI = "http://192.168.219.122:3007";
export const URI = process.env.REACT_APP_API_URL;
export const uriDivert = (pilotFlag = false) => {
  if (pilotFlag === true) {
    return "https://pilotacgtest.insahr.co.kr";
  } else {
    if (process.env.NODE_ENV === "production") {
      return process.env.REACT_APP_API_URL;
    } else if (process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_API_URL;
    }
  }
};

// export const URI_PILOT = "https://pilotacgtest.insahr.co.kr";

export const SUB_URI = process.env.REACT_APP_API_URL;
