import { Box, Chip, Typography } from "@mui/material";

export const tagRender = (input) => {
  switch (input) {
    case "본 검사 진행":
    case "본검사 진행":
      return (
        <Box mx="auto" width={"80%"} borderColor="#42BFDB" bgcolor="#42BFDB" borderRadius={1}>
          <Typography variant="body2" color="#FEFEFE" borderRadius={0.5} fontWeight={700} py={0.4}>
            검사 진행
          </Typography>
        </Box>
      );

    case "본 검사 대기":
    case "본검사 대기":
      return (
        <Box width={"80%"} mx="auto" border="1px solid #42BFDB" bgcolor="white" borderRadius={1}>
          <Typography variant="body2" color="#42BFDB" borderRadius={0.5} fontWeight={700} py={0.4}>
            검사 대기
          </Typography>
        </Box>
      );

    case "사전점검 진행":
      return (
        <Box mx="auto" width={"80%"} border="1px solid #42BFDB" bgcolor="#2A93AA" borderRadius={1}>
          <Typography variant="body2" color="white" borderRadius={0.5} fontWeight={700} py={0.4}>
            {input}
          </Typography>
        </Box>
      );

    case "사전점검 대기":
      return (
        <Box mx="auto" width={"80%"} border="1px solid #195B69" bgcolor="white" borderRadius={1}>
          <Typography variant="body2" color="#195B69" borderRadius={0.5} fontWeight={700} py={0.4}>
            {input}
          </Typography>
        </Box>
      );

    case "검사 종료":
      return (
        <Box mx="auto" bgcolor="#ccc" width={"80%"} borderRadius={1}>
          <Typography variant="body2" color="#FEFEFE" borderRadius={0.5} fontWeight={700} py={0.4}>
            {input}
          </Typography>
        </Box>
      );
    case "검사 미확정":
      return (
        <Box mx="auto" width={"80%"} border="1px solid #FF004E" bgcolor="white" borderRadius={1}>
          <Typography variant="body2" color="#FF004E" borderRadius={0.5} fontWeight={700} py={0.4}>
            {input}
          </Typography>
        </Box>
      );

    default:
      return "-";
  }
};

export const examStatusTagOnlyText = (input) => {
  switch (input) {
    case 0:
      return "미실시";
    case 1:
      return "진행중";
    case 2:
      return "검사완료";
    default:
      return "검사예정";
  }
};

export const examStatusTag = (input) => {
  switch (input) {
    case "미실시":
    case 0:
      return (
        <Chip
          label={"미실시"}
          sx={{
            fontWeight: 700,
            px: 0.5,
            "& > span": {
              fontSize: "0.9rem",
              color: "#91919e",
            },
          }}
          size="small"
        />
      );

    case "진행중":
    case 1:
      return (
        <Chip
          label={"진행중"}
          sx={{
            fontWeight: 700,
            background: "#6563FF",
            px: 0.5,
            "& > span": {
              fontSize: "0.9rem",
              color: "white",
            },
          }}
          size="small"
        />
      );

    case "검사완료":
    case 2:
      return (
        <Chip
          label={"검사완료"}
          sx={{
            fontWeight: 700,
            background: "#777777",
            px: 0.5,
            "& > span": {
              fontSize: "0.9rem",
              color: "white",
            },
          }}
          size="small"
        />
      );

    default:
      return (
        <Chip
          variant="outlined"
          label={"검사예정"}
          sx={{
            fontWeight: 700,
            borderColor: "#6563FF",
            "& > span": {
              fontSize: "0.9rem",
              color: "#6563FF",
            },
          }}
          size="small"
        />
      );
  }
};
