import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import styled from "@emotion/styled";

import NavComponent from "components/templates/nav/NavComponent";

import { Outlet, useParams } from "react-router-dom";

import { companyInfoAtom, isLayoutPage, isNavOpen } from "recoil/store";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { jwtDecoder } from "utils/jwtDecoder";
import { motion } from "framer-motion";

const MainContainer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, islayout }) => ({
  flexGrow: 1,
  minWidth: 0,
  position: "relative",
  padding: islayout === "true" ? 0 : theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-260px`,
  height: "100vh",
  background: islayout === "true" ? "white" : "#f8f9fa",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
export default function Content() {
  const isOpen = useRecoilValue(isNavOpen);
  const isLayout = useRecoilValue(isLayoutPage);
  const companyInfo = useSetRecoilState(companyInfoAtom);
  const { company } = useParams();

  useEffect(() => {
    companyInfo(
      jwtDecoder(
        sessionStorage.getItem(company) || localStorage.getItem(company)
      )
    );
  }, [company]);

  return (
    <Box sx={{ display: "flex", background: "#f8f9fa" }}>
      {!isLayout && <NavComponent />}
      <MainContainer open={isOpen} islayout={isLayout.toString()}>
        <motion.div
          // className="container text-center"
          style={{ height: "100%" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Outlet />
        </motion.div>
      </MainContainer>
    </Box>
  );
}
