import React, { useState, useCallback } from "react";

import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import { modalAtom } from "recoil/store";
import { useRecoilState } from "recoil";

import useGetQueryhr from "hooks/useGetQueryhr";
import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import { tableHeader, tableWrapper } from "themes/theme";
import { GROUP_TABLE_ROW, REMOVED_GROUP_TABLE_ROW } from "enums/tableColumn";
import { calcRowNum } from "utils/calcRowNum";
import { tagRender } from "utils/tagRender";
import { dateIncludeTime } from "utils/formatter/dateFormat";
import { checkboxSelect } from "utils/checkboxSelect";
import { MESSAGE_RESTORE_GROUP } from "enums/modalMessage";
import { Pagination, Paper } from "@mui/material";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";

const RemovedGroupModal = ({ onClose }) => {
  const [selected, setSelected] = useState([]);
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
  });
  const queryClient = useQueryClient();
  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQueryhr("removedGroupList", paramValues);

  //* 공고 등록 Mutation
  const { mutate } = useMutationHook("restoreGroup", "HR");

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
    setSelected([]);
    onClose();
  }, []);

  const handleCheckClick = (_, name) => {
    const { groupIdx } = name;
    const selectedIndex = selected.indexOf(groupIdx);
    setSelected(checkboxSelect(selected, selectedIndex, groupIdx));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.groupIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRestoreGroup = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_RESTORE_GROUP,
        title: "공고 복원",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            groupIdx: selected,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "공고 복원이 완료되었습니다.",
                  title: "공고 복원",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalGroup");
              });
            },
          }
        );
      }
    });
  };

  return (
    <>
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h6">공고복원 </Typography>
          <Button variant="contained" onClick={handleRestoreGroup}>
            복원
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ height: 450 }}>
          <Table stickyHeader sx={tableHeader} size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="none" align="center" sx={{ width: 5 }}>
                  <Checkbox
                    size="small"
                    style={{ color: "#637381" }}
                    onChange={handleSelectAllClick}
                    sx={{ px: 1 }}
                  />
                </TableCell>
                {REMOVED_GROUP_TABLE_ROW.map((item) => {
                  return item.flag === false ? null : (
                    <TableCell
                      padding="none"
                      key={item.label}
                      sx={{ width: item.size, fontWeight: "bold" }}
                      align="center"
                    >
                      {item.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.data.map((row, index) => (
                  <TableRow
                    hover
                    key={row.groupIdx}
                    sx={{
                      "&": { height: 45, cursor: "pointer" },
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    onClick={(event) => handleCheckClick(event, row)}
                  >
                    <TableCell padding="none" align="center">
                      <Checkbox
                        size="small"
                        color="primary"
                        onClick={(event) => handleCheckClick(event, row)}
                        checked={isSelected(row.groupIdx)}
                      />
                    </TableCell>
                    <TableCell padding="normal" align="center">
                      {calcRowNum(index, pageInfo, 20)}
                    </TableCell>
                    <TableCell padding="normal" align="center">
                      {row.testGroupYN === "Y" && (
                        <Badge color="secondary" badgeContent="TEST" />
                      )}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {tagRender(row.groupStatus)}
                    </TableCell>
                    {GROUP_TABLE_ROW[3].flag ? (
                      <TableCell padding="none" align="center">
                        {row.companyName}
                      </TableCell>
                    ) : null}
                    <TableCell padding="none">
                      <Typography>{row.groupName}</Typography>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {row.roomUseYN === "Y" ? (
                        <Grid
                          container
                          justifyContent={"center"}
                          columnGap={0.5}
                        >
                          <PhotoCameraIcon style={{ color: "#0439B4" }} />
                        </Grid>
                      ) : (
                        <NoPhotographyIcon style={{ color: "#FF004E" }} />
                      )}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {dateIncludeTime(new Date(row.examSAt))}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container alignItems="center" justifyContent="center" pt={2}>
          <Pagination
            size="small"
            count={pageInfo.totalPage}
            page={pageInfo.pageNo}
            onChange={(_, page) => {
              setParamValues((prev) => ({
                ...prev,
                pageNo: page,
              }));
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} columnGap={1}>
          <Button variant="outlined" onClick={onClose}>
            닫기
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default RemovedGroupModal;
