import React, { useCallback, lazy, Suspense, useState, useRef } from "react";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useGetQuery from "hooks/useGetQuery";
import useMutationHook from "hooks/useMutationHook";

import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";

import { dateFormat } from "utils/formatter/dateFormat";
import { iconBtnTheme, tableHeader, tableWrapper } from "themes/theme";
import { MESSAGE_DELETE_PRACTICE_GROUP } from "enums/modalMessage";

import { SUBJECT_GROUP_LIST } from "enums/tableColumn";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import { calcRowNum } from "utils/calcRowNum";
import { useEffect } from "react";
import { checkboxSelect } from "utils/checkboxSelect";
import { practiceType } from "utils/practiceType";
import { Chip } from "@mui/material";
import Swal from "sweetalert2";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import TopTitle from "components/templates/topTitle/TopTitle";

const CreateSubjectGroup = lazy(() =>
  import("components/pages/acg_admin/subject/CreateSubjectGroup")
);
const UpdateSubjectGroup = lazy(() =>
  import("components/pages/acg_admin/subject/UpdateSubjectGroup")
);

const SubjectGroupMain = () => {
  const queryClient = useQueryClient();
  const practiceNameRef = useRef();
  const navigate = useNavigate();
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
    perPage: 50,
  });

  const [selected, setSelected] = useState([]);

  const [perPage, setPerPage] = useState(20);
  const [createPracticeGroupModalOn, setCreatePracticeGroupModalOn] =
    useState(false);
  const [updatePracticeGroupModalOn, setUpdatePracticeGroupModalOn] =
    useState(false);

  const [selectTargetInfo, setSelectTargetInfo] = useState({});

  const handleUpdatePracticeGroupModal = (_, info) => {
    setUpdatePracticeGroupModalOn(!updatePracticeGroupModalOn);
    setSelectTargetInfo(info);
  };
  const handleCreatePracticeGroupModal = () =>
    setCreatePracticeGroupModalOn(!createPracticeGroupModalOn);

  const handleCheckClick = (_, name) => {
    const { practiceIdx } = name;
    const selectedIndex = selected.indexOf(practiceIdx);
    setSelected(checkboxSelect(selected, selectedIndex, practiceIdx));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //* QUERY
  const { data, pageInfo, isLoading, isFetching, isError, refetch } =
    useGetQuery("totalPractice", paramValues, true, false);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);
  //* 예제 삭제 Mutation
  const { mutate } = useMutationHook("deletePracticeGroup");

  //* 예제 삭제
  const handleDeletePracticeGroup = (_, practice) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_PRACTICE_GROUP,
        title: "예제 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            practiceIdx: selected[0],
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "예제 삭제가 완료되었습니다.",
                  title: "예제 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalPractice");
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: error.response.data.message,
                  title: "예제 삭제",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  const handleSearch = (e, name) => {
    if (name === "click") {
      setParamValues((prev) => ({
        ...prev,
        practiceName: practiceNameRef.current.value,
      }));
    } else {
      if (e.key === "Enter") {
        setParamValues((prev) => ({
          ...prev,
          practiceName: practiceNameRef.current.value,
        }));
      }
    }
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setParamValues((prev) => ({
      ...prev,
      perPage: e.target.value,
    }));
  };

  useEffect(() => {
    practiceNameRef.current.focus();
  }, []);

  return (
    <Grid
      width="100%"
      height="100%"
      borderRadius={2}
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      <TopTitle
        list={[
          {
            title: "과목 관리",
            url: `/subject`,
            current: true,
          },
        ]}
      />

      <Paper
        sx={{
          ...tableWrapper,
          overflowY: "hidden",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
          p: 2,
          rowGap: 2,
        }}
        variant="outlined"
      >
        <Box>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Stack direction={"row"} columnGap={1}>
              <Select
                size="small"
                defaultValue={perPage}
                onChange={handlePerPage}
              >
                <MenuItem key={10} value={10}>
                  10/Page
                </MenuItem>
                <MenuItem key={20} value={20}>
                  20/Page
                </MenuItem>
                <MenuItem key={50} value={50}>
                  50/Page
                </MenuItem>
              </Select>
              <TextField
                size="small"
                placeholder="예제명을 입력하세요"
                sx={{ minWidth: 300 }}
                inputRef={practiceNameRef}
                onKeyUp={(e) => handleSearch(e, "enter")}
              />
              <Button
                variant="contained"
                onClick={(e) => handleSearch(e, "click")}
              >
                검색
              </Button>
            </Stack>
            <Stack direction="row" columnGap={1}>
              <Tooltip title="문항삭제는 1개씩만 가능합니다." arrow>
                <Box component={"span"}>
                  <IconButton
                    size="small"
                    disabled={selected.length > 1 || selected.length < 1}
                    onClick={handleDeletePracticeGroup}
                    className="removeIconButton"
                    sx={{
                      background: "#fcd9d9",
                      color: "#ffa8a8",
                    }}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title="예제 추가" arrow placement="top">
                <Box component="span">
                  <IconButton
                    sx={iconBtnTheme}
                    size="small"
                    variant="contained"
                    onClick={handleCreatePracticeGroupModal}
                  >
                    <AddRoundedIcon color="info" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
          </Grid>
        </Box>

        <TableContainer sx={{ height: "calc(95% - 20px)" }}>
          <Table stickyHeader sx={tableHeader} size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="none" align="center" sx={{ width: 5 }}>
                  <Checkbox size="small" />
                </TableCell>
                {SUBJECT_GROUP_LIST.map((item) => {
                  return item.flag === false ? null : (
                    <TableCell
                      padding="none"
                      key={item.label}
                      sx={{ width: item.size, fontWeight: "bold" }}
                      align="center"
                    >
                      {item.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data?.length < 1 ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell colSpan={17} height={300}>
                    <EmptyPlaceholder message={"조회내용이 없습니다."} />
                  </TableCell>
                </TableRow>
              ) : (
                data &&
                data.data?.map((row, index) => (
                  <TableRow
                    hover
                    key={row.practiceIdx}
                    sx={{
                      "&": { height: 45, cursor: "pointer" },
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell padding="none" align="center">
                      <Checkbox
                        size="small"
                        color="primary"
                        onClick={(event) => handleCheckClick(event, row)}
                        checked={isSelected(row.practiceIdx)}
                      />
                    </TableCell>
                    <TableCell
                      padding="normal"
                      align="center"
                      onClick={() => navigate(`/practice/${row.practiceIdx}`)}
                    >
                      {calcRowNum(index, pageInfo, perPage)}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      onClick={() => navigate(`/practice/${row.practiceIdx}`)}
                    >
                      {practiceType(row) === "APTITUDE" ? (
                        <Chip
                          label="적성"
                          variant="outlined"
                          size="small"
                          sx={{
                            "&": { border: "0.6px solid #2571ff", px: 1 },
                            "&>span": {
                              fontWeight: 700,
                              fontSize: "0.5rem",
                              color: "#00328e",
                            },
                          }}
                        />
                      ) : (
                        <Chip
                          label="인성"
                          variant="outlined"
                          size="small"
                          sx={{
                            "&": { border: "0.6px solid tomato", px: 1 },
                            "&>span": {
                              fontWeight: 700,
                              fontSize: "0.5rem",
                              color: "tomato",
                            },
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      sx={{ position: "relative" }}
                      onClick={() => navigate(`/subject/${row.practiceIdx}`)}
                    >
                      {row.practiceName}
                      {row.preExamUseYN === "N" && (
                        <Chip
                          label="사전점검 X"
                          variant="outlined"
                          size="small"
                          sx={{
                            position: "absolute",
                            transform: "translateX(20%)",
                            top: 5,
                            "&": { border: "0.6px solid tomato" },
                            "&>span": { fontSize: "0.5rem", color: "tomato" },
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      onClick={() => navigate(`/subject/${row.practiceIdx}`)}
                    >
                      {row.practiceMemo}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      onClick={() => navigate(`/subject/${row.practiceIdx}`)}
                    >
                      {row.totalPractice}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      onClick={() => navigate(`/subject/${row.practiceIdx}`)}
                    >
                      {row.practiceMinutes}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      onClick={() => navigate(`/subject/${row.practiceIdx}`)}
                    >
                      {dateFormat(new Date(row.createdAt))}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <IconButton
                        size="small"
                        onClick={(e) => handleUpdatePracticeGroupModal(e, row)}
                        sx={{
                          border: "1px solid #ddd9d996",
                          borderRadius: 2,
                        }}
                      >
                        <EditRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container alignItems={"center"} justifyContent="center">
          <Pagination
            size="small"
            count={pageInfo.totalPage}
            page={pageInfo.pageNo}
            onChange={(_, page) => {
              setParamValues((prev) => ({
                ...prev,
                pageNo: page,
              }));
            }}
          />
        </Grid>
      </Paper>
      <Dialog
        maxWidth={"xs"}
        fullWidth
        open={updatePracticeGroupModalOn}
        onClose={handleUpdatePracticeGroupModal}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateSubjectGroup
            onClose={handleUpdatePracticeGroupModal}
            selectTargetInfo={selectTargetInfo}
          />
        </Suspense>
      </Dialog>
      <Dialog
        maxWidth={"xs"}
        fullWidth
        open={createPracticeGroupModalOn}
        onClose={handleCreatePracticeGroupModal}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <CreateSubjectGroup onClose={handleCreatePracticeGroupModal} />
        </Suspense>
      </Dialog>
    </Grid>
  );
};

export default SubjectGroupMain;
