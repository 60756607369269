import React, { useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MESSAGE_DOWNLOAD_TESTER_STATUS_LIST } from "enums/modalMessage";
import useMutationHook from "hooks/useMutationHook";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";

const TesterStatusListDownloadModal = ({
  groupIdx,
  onClose,
  pilotFlag = false,
}) => {
  const inputReason = useRef();

  //* 마킹값 다운로드 Mutation
  const {
    mutate: downloadTesterList,
    isLoading,
    isSuccess,
  } = useMutationHook("testerStatusList", "HR", pilotFlag);

  const handleDownload = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_TESTER_STATUS_LIST,
        title: "응시자 현황 리스트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        downloadTesterList(
          {
            eventReason: inputReason.current.value,
            groupIdx: groupIdx,
          },
          {
            onSuccess: (res) => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 현황 리스트 다운로드가 완료되었습니다.",
                  title: "응시자 현황 리스트 다운로드",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  window.open(`https://${res.data.data}`);
                  onClose();
                }
              });
            },
          }
        );
      }
    });
  };

  return (
    <>
      <DialogTitle variant="h5">응시자 현황 다운로드</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems="center" mt={2}>
          <Grid item xs={2}>
            <Typography fontWeight={700}>사유입력</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              label="다운로드 사유"
              fullWidth
              size="small"
              inputRef={inputReason}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
        <Button variant={"contained"} onClick={handleDownload}>
          확인
        </Button>
      </DialogActions>
    </>
  );
};

export default TesterStatusListDownloadModal;
