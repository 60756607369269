import { Suspense, lazy, useCallback, useEffect, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ReactComponent as AddTester } from "assets/icon/UsergroupAddOutlined.svg";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import GroupCard from "components/pages/acg_hr/group/main/groupCards/GroupCard";
import DownloadProgressModal from "components/pages/acg_hr/group/main/roomList/DownloadProgressModal";
import NavOpenBtn from "components/templates/NavOpenBtn";

import { useRecoilState } from "recoil";
import { perPageAtom } from "recoil/store";

import { useQueryClient } from "@tanstack/react-query";
import useGetFile from "hooks/useGetFile";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

import { useParams } from "react-router-dom";

import { ListItemIcon } from "@mui/material";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import { MESSAGE_DOWNLOAD_GROUP_REPORT, MESSAGE_DOWNLOAD_PERSONAL_REPORT } from "enums/modalMessage";
import { GROUP_ROOM_LIST_NO_VIDEO, GROUP_TESTERLIST_MORE_LIST } from "enums/tableColumn";
import { Database, Download, FileText, LayoutList, Trash2, User2, Users2 } from "lucide-react";
import Swal from "sweetalert2";
import { tableHeader, tableWrapper } from "themes/theme";
import { checkboxSelect } from "utils/checkboxSelect";
import { YNColor } from "utils/formatter/YNColor";
import { dateFormatTime } from "utils/formatter/dateFormat";
import { examStatusTag } from "utils/tagRender";
import TopTitle from "../../../../../templates/topTitle/TopTitle";
import TesterStatusListDownloadModal from "../roomList/TesterStatusListDownloadModal";
import IdCardCheckModal from "../roomList/testerMenu/IdCardCheckModal";
import TesterVideoLogModal from "../roomList/testerMenu/TesterVideoLogModal";
const UpdateTesterModal = lazy(() =>
  import("components/pages/acg_hr/group/main/roomList/testerMenu/UpdateRoomTesterModal")
);
const UpdateTestStatusModal = lazy(() =>
  import("components/pages/acg_hr/group/main/roomList/testerMenu/UpdateTestStatusModal")
);
const TesterLogDataModal = lazy(() =>
  import("components/pages/acg_hr/group/main/roomList/testerMenu/TesterLogDataModal")
);
const CreateOneTester = lazy(() => import("components/pages/acg_hr/group/main/roomList/CreateOneTester"));
const CreateTesterModal = lazy(() => import("components/pages/acg_hr/group/main/roomList/CreateTesterModal"));

const DownloadGroupMarkDataModal = lazy(() =>
  import("components/pages/acg_hr/group/main/roomList/DownloadGroupMarkDataModal")
);

export default function TesterList() {
  const { id, company } = useParams();
  const [addOneTesterModalOn, setAddOneTesterModalOn] = useState(false);
  const [selected, setSelected] = useState([]);
  const [updateTesterModalOn, setUpdateTesterModalOn] = useState(false);
  const [updateTestStatusModal, setUpdateTestStatusModal] = useState(false);
  const [logDataModal, setLogDataModal] = useState(false);
  const [createTesterModalOn, setCreateTesterModalOn] = useState(false);
  const [perPage, setPerPage] = useRecoilState(perPageAtom);
  const [testerStatusListModalOn, setTesterStatusListModalOn] = useState(false);
  const [reportDownloadToggle, setReportDownloadToggle] = useState(false);
  const [progressModalOn, setProgressModalOn] = useState(false);
  const [targetProgress, setTargetProgress] = useState(0);
  const [testerVideoLogModal, setTesterVideoLogModal] = useState(false);
  const [reportDownloadPersonalToggle, setReportDownloadPersonalToggle] = useState(false);
  const [idCardCheckModal, setIdCardCheckModal] = useState(false);
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
    groupIdx: Number(id),
    perPage: 400,
  });

  const { data: groupInfo } = useGetQueryhr("getGroupDetail", { groupIdx: id });

  const [markType, setMarkType] = useState("");

  const queryClient = useQueryClient();

  const { data, pageInfo, isLoading, isFetching } = useGetQueryhr("testerList", paramValues);

  useGetFile("HR", "reportDownloadGroup", { groupIdx: Number(id) }, reportDownloadToggle, setReportDownloadToggle);

  const [moreBtnAnchor, setMoreBtnAnchor] = useState(null);
  const openMoreBtn = Boolean(moreBtnAnchor);

  const handleClickMenu = (event, row) => {
    setMoreBtnAnchor(event.currentTarget);
    setTesterInfo(row);
  };

  const handleCloseMoreBtn = () => {
    setMoreBtnAnchor(null);
  };
  const handleProgressModal = () => setProgressModalOn(!progressModalOn);
  //* 전체 HR 레포트 탬플릿 생성
  const { mutate: createGroupHRreport } = useMutationHook("groupHRreport", "HR");

  //*   모든 개인 레포트 탬플릿 생성
  const { mutate: createPersonalHRreport } = useMutationHook("personalHRreport", "HR");

  //* 응시자 삭재
  const { mutate: deleteTester } = useMutationHook("deleteTesters", "HR");

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleDeleteTester = () => {
    Swal.fire(
      confirmModalConfig({
        text: "선택하신 응시자를 삭제하시겠습니까?",
        title: "응시자 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteTester(
          { testerIdx: selected, groupIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 삭제가 완료되었습니다.",
                  title: "응시자 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("testerList");
              });
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    setPerPage(20);
  }, []);

  //* MODAL
  const handleAddOneTester = () => setAddOneTesterModalOn(!addOneTesterModalOn);

  //* CheckBox
  const handleCheckClick = (_, name) => {
    const { testerIdx } = name;
    const selectedIndex = selected.indexOf(testerIdx);
    setSelected(checkboxSelect(selected, selectedIndex, testerIdx));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //* MODAL
  const handleUpdateTesterModalOn = () => setUpdateTesterModalOn(!updateTesterModalOn);
  const handleUpdateTestStatusModalOn = () => setUpdateTestStatusModal(!updateTestStatusModal);
  const handleLogDataModalOn = () => setLogDataModal(!logDataModal);
  const handleCreateTesterModal = () => setCreateTesterModalOn(!createTesterModalOn);
  const handleIdCardCheckModalOn = (_, reason) => {
    setIdCardCheckModal((prev) => !prev);
  };
  const handleTesterVideoLogModal = (_, reason) => {
    setTesterVideoLogModal((prev) => !prev);
  };

  //* 더보기 메뉴

  const handleSelectMenu = (e, row) => {
    console.log("row: ", row);
    if (e.target.value === 1) handleUpdateTesterModalOn();
    if (e.target.value === 2) handleUpdateTestStatusModalOn();
    if (e.target.value === 3) handleIdCardCheckModalOn();
    if (e.target.value === 4) handleLogDataModalOn();
    if (e.target.value === 8) handleTesterVideoLogModal();

    setMoreBtnAnchor(null);
  };

  const [testerInfo, setTesterInfo] = useState({});

  //* 전체선택 체크박스
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.testerIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [downloadGroupMarkDataModalOn, setDownloadGroupMarkDataModalOn] = useState(false);

  const handleDownloadGroupMarkData = (_, type) => {
    setMarkType(type);
    handleCloseDownloadListMenu();
    handleCloseDownloadMarkData();
    setDownloadGroupMarkDataModalOn(!downloadGroupMarkDataModalOn);
  };

  const handleCreateGroupHRreport = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    handleCloseReportDownloadListMenu();

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_GROUP_REPORT,
        title: "레포트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        createGroupHRreport(
          {
            groupIdx: Number(id),
          },
          {
            onSuccess: () => {
              setReportDownloadToggle(!reportDownloadToggle);
            },
            onError: () => {
              Swal.fire(
                alertModalConfig({
                  text: "리포트 다운로드를 실패하였습니다.",
                  title: "레포트 다운로드",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  const isSuccess = (res) => {
    const { progressNo, totalProgress } = res.data.data;
    setTargetProgress(Math.round((progressNo / totalProgress) * 100));

    if (progressNo >= totalProgress) {
      return;
    }

    createPersonalHRreport(
      {
        groupIdx: Number(id),
        progressNo: progressNo + 1,
      },
      {
        onSuccess: (res) => {
          isSuccess(res);
        },
      }
    );
  };

  const handleCreatePersonalReport = () => {
    handleCloseDownloadListMenu();
    handleCloseReportDownloadListMenu();

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_PERSONAL_REPORT,
        title: "레포트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        createPersonalHRreport(
          {
            groupIdx: Number(id),
            progressNo: 1,
          },
          {
            onSuccess: (res) => {
              isSuccess(res);
              handleProgressModal();
            },
          }
        );
      }
    });
  };
  const [downloadMarkDataAnchorEl, setDownloadMarkDataAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [reportMenuEl, setReportMenuEl] = useState(null);
  const openDownloadMenu = Boolean(downloadAnchorEl);
  const openDownloadReportMenu = Boolean(reportMenuEl);
  const openDownloadMarkDataMenu = Boolean(downloadMarkDataAnchorEl);
  const handleOpenDownloadListMenu = (e) => setDownloadAnchorEl(e.currentTarget);
  const handleCloseDownloadListMenu = () => setDownloadAnchorEl(null);

  const handleCloseReportDownloadListMenu = () => setReportMenuEl(null);
  const handleOpenDownloadReportListMenu = (e) => setReportMenuEl(e.currentTarget);

  const handleDownloadTesterStatusList = () => {
    handleCloseDownloadListMenu();
    setTesterStatusListModalOn(!testerStatusListModalOn);
  };

  const handleCloseDownloadMarkData = () => setDownloadMarkDataAnchorEl(null);

  const handleOpenDownloadMarkData = (e) => setDownloadMarkDataAnchorEl(e.currentTarget);
  return (
    <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
      <NavOpenBtn />
      <Grid container justifyContent={"space-between"}>
        <TopTitle
          list={[
            {
              title: "채용그룹 관리",
              url: `/acghr_hr/${company}/group`,
              current: false,
            },
            {
              title: "응시자 목록",
              url: `/acghr_hr/${company}/testerList${id}`,
              current: true,
            },
          ]}
        />

        <Box>
          <Grid item container columnGap={1}>
            <Button startIcon={<Download size={15} />} onClick={handleOpenDownloadListMenu} variant="outlined">
              다운로드
            </Button>
            <Menu anchorEl={downloadAnchorEl} open={openDownloadMenu} onClose={handleCloseDownloadListMenu}>
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={handleOpenDownloadMarkData}
              >
                <ListItemIcon>
                  <Database size={17} />
                </ListItemIcon>
                응답값
              </MenuItem>
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={handleOpenDownloadReportListMenu}
              >
                <ListItemIcon>
                  <FileText size={17} />
                </ListItemIcon>
                레포트
              </MenuItem>
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={handleDownloadTesterStatusList}
              >
                <ListItemIcon>
                  <LayoutList size={17} />
                </ListItemIcon>
                응시자 현황
              </MenuItem>
            </Menu>
            <Menu
              anchorEl={reportMenuEl}
              open={openDownloadReportMenu}
              onClose={handleCloseReportDownloadListMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={handleCreateGroupHRreport}
              >
                <ListItemIcon>
                  <Users2 size={17} />
                </ListItemIcon>
                그룹
              </MenuItem>
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={handleCreatePersonalReport}
              >
                <ListItemIcon>
                  <User2 size={17} />
                </ListItemIcon>
                개인
              </MenuItem>
            </Menu>

            <Menu
              anchorEl={downloadMarkDataAnchorEl}
              open={openDownloadMarkDataMenu}
              onClose={handleCloseDownloadMarkData}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={(_) => handleDownloadGroupMarkData(_, "MARK")}
              >
                <ListItemIcon>
                  <Users2 size={17} />
                </ListItemIcon>
                Raw Data
              </MenuItem>

              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={(_) => handleDownloadGroupMarkData(_, "INTERVAL")}
              >
                <ListItemIcon>
                  <User2 size={17} />
                </ListItemIcon>
                풀이시간
              </MenuItem>
            </Menu>
          </Grid>
        </Box>
      </Grid>
      <Grid>
        {groupInfo && (
          <Card
            variant="outlined"
            sx={{
              background: "transparent",
              border: "none",
              "& .MuiCardContent-root:last-child": { padding: 0 },
            }}
          >
            <CardContent>
              <GroupCard selectedItem={groupInfo} status={isLoading || isFetching} />
            </CardContent>
          </Card>
        )}
      </Grid>

      <Grid container alignItems="center" justifyContent={"space-between"} flexWrap="nowrap">
        <Grid item container alignItems="center" columnGap={1}>
          <Typography variant="h6" fontWeight={700}>{`총 ${pageInfo.total}명`}</Typography>
        </Grid>
        <Grid item container columnGap={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleCreateTesterModal}
              sx={{ "&>span>svg": { width: 20, height: 20 } }}
              startIcon={<AddTester />}
            >
              응시자
            </Button>
          </Grid>

          <Grid item>
            <Button
              startIcon={<Trash2 size={15} />}
              variant="contained"
              color="removeButton"
              disableElevation
              onClick={handleDeleteTester}
              sx={{ "&": { color: "#c42c08" } }}
              disabled={selected.length <= 0 ? true : false}
            >
              응시자 삭제
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {data && data.data.length < 1 ? (
        <EmptyPlaceholder />
      ) : (
        <>
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            variant="outlined"
          >
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox size="small" style={{ color: "#637381" }} onChange={handleSelectAllClick} />
                    </TableCell>
                    {GROUP_ROOM_LIST_NO_VIDEO.map((item) => (
                      <TableCell key={item.label} sx={{ width: item.size, fontWeight: "bold" }} align="center">
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.data?.map((row) => (
                      <TableRow
                        hover
                        key={row.testerIdx}
                        sx={{
                          "&": { height: 43 },
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell padding="none" align="center">
                          <Checkbox
                            size="small"
                            color="primary"
                            onClick={(event) => handleCheckClick(event, row)}
                            checked={isSelected(row.testerIdx)}
                          />
                        </TableCell>
                        <TableCell padding="none" align="center" component="th" scope="row">
                          {row.testerName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.testerBirth}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.testerCell}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {YNColor(row.foreignLoginYN)}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          {dateFormatTime(row.logAt)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.normName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {examStatusTag(row.examStatus)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          <Button onClick={(e) => handleClickMenu(e, row)}>
                            <MoreHorizIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu anchorEl={moreBtnAnchor} open={openMoreBtn} onClose={handleCloseMoreBtn}>
              {GROUP_TESTERLIST_MORE_LIST.map((item, index) => {
                return (
                  <MenuItem key={index} onClick={(e) => handleSelectMenu(e, testerInfo)} value={item.index}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Menu>
            <Grid container alignItems={"center"} justifyContent="center" my={1}>
              <Pagination
                size="small"
                count={pageInfo.totalPage}
                page={pageInfo.pageNo}
                onChange={(_, page) => {
                  setParamValues((prev) => ({ ...prev, pageNo: page }));
                }}
              />
            </Grid>
          </Paper>
        </>
      )}

      {/* 응시자 개별등록 */}
      <Dialog open={addOneTesterModalOn} onClose={handleAddOneTester} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingSkeleton />}>
          <CreateOneTester groupIdx={id} onClose={handleAddOneTester} />
        </Suspense>
      </Dialog>

      {/* 응시자 정보수정 */}
      <Dialog open={updateTesterModalOn} onClose={handleUpdateTesterModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateTesterModal
            detail={selected}
            roomTesterInfo={testerInfo}
            onClose={handleUpdateTesterModalOn}
            queryKey="testerList"
          />
        </Suspense>
      </Dialog>

      {/* 응시자 진행상태 정보 수정 */}
      <Dialog open={updateTestStatusModal} onClose={handleUpdateTestStatusModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateTestStatusModal
            detail={selected}
            roomTesterInfo={testerInfo}
            onClose={handleUpdateTestStatusModalOn}
          />
        </Suspense>
      </Dialog>

      {/* 응시자 접속로그 데이터 모달 */}
      <Dialog open={logDataModal} onClose={handleLogDataModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterLogDataModal roomTesterInfo={testerInfo} onClose={handleLogDataModalOn} />
        </Suspense>
      </Dialog>
      {/* 비디오로그 모달 */}
      <Dialog open={testerVideoLogModal} onClose={handleTesterVideoLogModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterVideoLogModal roomTesterInfo={testerInfo} onClose={handleTesterVideoLogModal} />
        </Suspense>
      </Dialog>
      {/* 응시자 등록 모달 */}
      <Dialog open={createTesterModalOn} onClose={handleCreateTesterModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingSkeleton />}>
          <CreateTesterModal queryKey="testerList" groupIdx={id} onClose={handleCreateTesterModal} />
        </Suspense>
      </Dialog>

      {/* 그룹 마킹값 다운로드 */}
      <Dialog open={downloadGroupMarkDataModalOn} onClose={handleDownloadGroupMarkData} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadGroupMarkDataModal groupIdx={id} onClose={handleDownloadGroupMarkData} markType={markType} />
        </Suspense>
      </Dialog>

      {/* 신분증 확인 모달 */}
      <Dialog open={idCardCheckModal} onClose={handleIdCardCheckModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingCircle />}>
          <IdCardCheckModal roomTesterInfo={testerInfo} onClose={handleIdCardCheckModalOn} />
        </Suspense>
      </Dialog>
      {/* 응시자 현황 다운로드 */}
      <Dialog open={testerStatusListModalOn} onClose={handleDownloadTesterStatusList} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <TesterStatusListDownloadModal groupIdx={id} onClose={handleDownloadTesterStatusList} />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog open={progressModalOn} onClose={handleProgressModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModal
            onClose={handleProgressModal}
            value={targetProgress}
            id={Number(id)}
            reportDownloadPersonalToggle={reportDownloadPersonalToggle}
            setReportDownloadPersonalToggle={setReportDownloadPersonalToggle}
          />
        </Suspense>
      </Dialog>
    </Box>
  );
}
