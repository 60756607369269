import React, { useCallback, useRef } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  modalAtom,
  questionPageDetailAtom,
  questionsDetailAtom,
} from "recoil/store";

import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import { MESSAGE_UPDATE_QUESTION_GROUP } from "enums/modalMessage";
import { practiceType } from "utils/practiceType";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";

const EditQuestionPersonal = ({ targetIndex, onClose, pageNo }) => {
  const {
    questionNo,
    questionText,
    questionSubText,
    questionIdx,
    questionExList,
  } = targetIndex || {};

  const questionDetail = useRecoilValue(questionsDetailAtom);
  const questionPageDetail = useRecoilValue(questionPageDetailAtom);
  const queryClient = useQueryClient();

  const { mutate } = useMutationHook("updateQuestionDetail");

  const questionTextRef = useRef();
  const questionSubTextRef = useRef();
  const questionNoRef = useRef();
  const questionExNoRef = useRef([]);

  const alertResult = useCallback((key) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleSubmit = () => {
    const submitValues = {
      questionTitleRegistInfo: {
        questionNo: Number(questionNoRef.current.value),
        questionText: questionTextRef.current.value,
        questionSubUseYN: practiceType(questionDetail) === "TYPE2" ? "N" : "Y",
        questionSubText:
          practiceType(questionDetail) === "TYPE2"
            ? null
            : questionSubTextRef.current.value,

        questionExLayout: 3,
      },
      questionExRegistList:
        practiceType(questionDetail) === "TYPE3" ? [] : questionExList,
      pageNo: pageNo,
      likertYN: questionDetail.likertYN,
      maxScale: questionDetail.maxScale,
      ipsativeYN: questionDetail.ipsativeYN,
      questionSetCnt:
        questionDetail.ipsativeYN === "Y"
          ? questionDetail.questionSetCnt
          : null,
    };

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_QUESTION_GROUP,
        title: "문항 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            questionIdx: questionIdx,
            values: submitValues,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 수정이 완료되었습니다.",
                  title: "문항 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("questionDetail2");
              });
            },
          }
        );
      }
    });
  };

  return (
    <>
      <DialogTitle>문항수정</DialogTitle>
      <DialogContent>
        <Grid container direction="column" rowGap={2}>
          {questionPageDetail.questionList.length < 1 && pageNo === 1 && (
            <Grid item xs container columnGap={1} direction="column">
              <Typography fontWeight={700}>응답척도</Typography>
              <Grid container justifyContent="space-between" columnGap={1}>
                {[...Array(questionDetail.maxScale)].map((item, index) => {
                  return (
                    <Grid item xs>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="척도명을 입력하세요."
                        inputRef={(el) => (questionExNoRef.current[index] = el)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          <Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mb={1}
            >
              <Typography fontWeight={700}>문항내용</Typography>
            </Grid>
            <Grid container direction="column" rowGap={1}>
              <Grid container columnGap={1}>
                <Grid item xs={0.7}>
                  <TextField
                    size="small"
                    placeholder="번호"
                    name="questionNo"
                    defaultValue={questionNo}
                    inputRef={questionNoRef}
                    sx={{ "&>div>input": { textAlign: "center" } }}
                  />
                </Grid>
                {questionDetail.likertYN === "Y" &&
                questionDetail.ipsativeYN === "N" ? null : (
                  <Grid item xs={0.7}>
                    <TextField
                      size="small"
                      placeholder="하위 문항번호"
                      name="questionSubText"
                      inputRef={questionSubTextRef}
                      defaultValue={questionSubText}
                      sx={{ "&>div>input": { textAlign: "center" } }}
                    />
                  </Grid>
                )}
                <Grid item xs>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="문항내용을 입력하세요."
                    name="questionText"
                    inputRef={questionTextRef}
                    defaultValue={questionText}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
};

export default EditQuestionPersonal;
