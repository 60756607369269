import React, { useCallback, useRef, Suspense, useEffect, useState, lazy } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

import LoadingCircle from "components/UI/organism/LoadingCircle";

import { perPageAtom } from "recoil/store";
import { useRecoilState } from "recoil";

import useGetQuery from "hooks/useGetQuery";
import useMutationHook from "hooks/useMutationHook";
import { useQueryClient } from "@tanstack/react-query";

import { tableHeader, tableWrapper } from "themes/theme";
import { MESSAGE_COPY_TERM, MESSAGE_UPDATE_TERM } from "enums/modalMessage";
import { calcRowNum } from "utils/calcRowNum";
import { TERMS_COL } from "enums/tableColumn";
import { dateFormat } from "utils/formatter/dateFormat";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import TopTitle from "components/templates/topTitle/TopTitle";
import { Trash2 } from "lucide-react";
//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);

const TermDeleteConfirmModal = lazy(() => import("components/pages/acg_admin/terms/TermDeleteComfirmModal"));

export default function ManageTerms() {
  const [termsName, setTermsName] = useState("");

  const [paramValues, setParamValues] = useState({
    page: { pageNo: 1 },
    search: { termsName: "" },
    perPage: 20,
  });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [termsIdx, setTermsIdx] = useState(0);
  const [isTermDelete, setIsTermDelete] = useState(false);
  const [isUpdateName, setIsUpdateName] = useState(false);
  const [selectInfo, setSelectInfo] = useState();
  const [perPage, setPerPage] = useRecoilState(perPageAtom);
  const queryClient = useQueryClient();
  const termsNameRef = useRef(termsName);

  const { data, pageInfo, isLoading, isError, refetch, isFetching } = useGetQuery("totalTerms", paramValues);
  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  useEffect(() => {
    setPerPage(20);
  }, []);
  //*  Mutation
  const { mutate } = useMutationHook("updateTerms");
  const { mutate: copyTerm } = useMutationHook("copyTerm");

  const submitSearch = () => {
    setParamValues((prev) => ({
      ...prev,
      search: { termsName: searchRef.current.value || "" },
    }));
  };

  const noticeRef = useRef();
  const privacyRef = useRef();
  const secretRef = useRef();

  const [currentTerm, setCurrentTerm] = useState({});

  const handleSetNowTerm = (row) => {
    setCurrentTerm(row);

    setTermsName((prev) => ({
      ...prev,
      termsName: row.termsName,
    }));
    setTermsIdx((prev) => ({
      ...prev,
      termsIdx: row.termsIdx,
    }));
  };

  useEffect(() => {
    termsNameRef.current = termsName;
  }, [termsName]);

  const handleUpdateTerms = () => {
    let submitData = {
      ...{
        examNotice: noticeRef.current.value,
        examPrivacy: privacyRef.current.value,
        examSecret: secretRef.current.value,
      },
      ...{ termsName: termsName.termsName },
    };

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_TERM,
        title: "약관 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { termsIdx: termsIdx, submitData },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "약관 수정이 완료되었습니다.",
                  title: "약관 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalTerms");
              });
            },
          }
        );
      }
    });
  };

  const handleDeleteTerms = () => setIsTermDelete(!isTermDelete);
  const handleIsUpdateName = () => {
    termsNameRef.current = termsName;
    setIsUpdateName(!isUpdateName);
  };
  const handleCollapse = () => setIsCollapsed(!isCollapsed);
  const searchRef = useRef();

  const handleCancelEditTermsName = () => {
    termsNameRef.current = termsName;
    handleIsUpdateName();
  };

  const handleCopyTerm = (_, row) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_COPY_TERM,
        title: "약관 복사",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        copyTerm(
          { termsIdx: row.termsIdx },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "약관 복사가 완료되었습니다.",
                  title: "약관 복사",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalTerms");
              });
            },
          }
        );
      }
    });
  };

  const handleConfirmTermsName = () => {
    setIsUpdateName(!isUpdateName);
    setTermsName((prev) => ({
      ...prev,
      termsName: termsNameRef.current.value,
    }));
  };

  return (
    <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
      <TopTitle
        list={[
          {
            title: "약관 관리",
            url: `/terms`,
            current: true,
          },
        ]}
      />

      <Grid container columns={16} bgcolor="white" p={2} borderRadius={2}>
        {/* 1열 */}
        {/* <Collapse in={isCollapsed} orientation="horizontal" collapsedSize={10}> */}
        <Grid item xs={isCollapsed ? 15 : 7.5}>
          <Box>
            <Grid
              container
              justifyContent={"center"}
              alignItems="center"
              columnGap={1}
              mb={3}
              borderRadius={2}
              p={3}
              bgcolor="#eeeeee"
            >
              {isUpdateName ? (
                <>
                  <TextField
                    size="small"
                    placeholder="약관이름을 입력하세요"
                    sx={{
                      minWidth: "80%",
                      input: {
                        fontSize: "1.25rem",
                        textAlign: "center",
                        fontWeight: 500,
                        padding: 0.5,
                      },
                    }}
                    defaultValue={termsNameRef.current?.termsName}
                    inputRef={termsNameRef}
                    // onChange={handleTermsName}
                  />
                  <IconButton sx={{ padding: 0 }} onClick={handleConfirmTermsName}>
                    <TaskAltIcon color="info" />
                  </IconButton>
                  <IconButton sx={{ padding: 0 }} onClick={handleCancelEditTermsName}>
                    <CloseIcon color="warning" />
                  </IconButton>
                </>
              ) : (
                <Typography variant="h6">{`${termsName.termsName || "약관을 선택해 주세요"}`}</Typography>
              )}

              {termsName.termsName && !isUpdateName && <DriveFileRenameOutlineIcon onClick={handleIsUpdateName} />}
            </Grid>

            {/* 1 of TERMS  */}

            <Grid container rowGap={2} direction="column">
              <Box>
                <Grid container justifyContent={"space-between"} alignItems="center" mb={0.5}>
                  <Typography variant="h6">개인정보 이용 수집 동의</Typography>
                </Grid>

                <ReactQuill
                  ref={privacyRef}
                  style={{
                    border: "1px solid #bfbfbf",
                    overflow: "auto",
                    resize: "vertical",
                    minHeight: "150px",
                  }}
                  placeholder="내용을 입력해 주세요"
                  value={currentTerm.examPrivacy}
                />
              </Box>
              <Box>
                <Grid container justifyContent={"space-between"} alignItems="center" mb={0.5}>
                  <Typography variant="h6">정보 보안 서약</Typography>
                </Grid>

                <ReactQuill
                  ref={secretRef}
                  style={{
                    border: "1px solid #bfbfbf",
                    overflow: "auto",
                    resize: "vertical",
                    minHeight: "150px",
                  }}
                  placeholder="내용을 입력해 주세요"
                  value={currentTerm.examSecret}
                />
              </Box>
              <Box>
                <Grid container justifyContent={"space-between"} alignItems="center" mb={0.5}>
                  <Typography variant="h6">응시자 유의사항</Typography>
                </Grid>

                <ReactQuill
                  ref={noticeRef}
                  style={{
                    border: "1px solid #bfbfbf",
                    overflow: "auto",
                    resize: "vertical",
                    minHeight: "150px",
                  }}
                  placeholder="내용을 입력해 주세요"
                  value={currentTerm.examNotice}
                />
              </Box>
            </Grid>
            <Grid container justifyContent={"center"} mt={4}>
              <Button variant="contained" onClick={handleUpdateTerms}>
                수정
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem>
          {isCollapsed ? (
            <KeyboardDoubleArrowLeftIcon onClick={handleCollapse} />
          ) : (
            <KeyboardDoubleArrowRightIcon onClick={handleCollapse} />
          )}
        </Divider>
        {/* 2열 */}
        {!isCollapsed && (
          <Grid item xs={7.5}>
            {/* <Box> */}
            <Grid container justifyContent={"flex-end"} columnGap={1}>
              <TextField size="small" placeholder="약관 검색" inputRef={searchRef} />
              <Button size="small" variant="contained" onClick={submitSearch}>
                검색
              </Button>
            </Grid>
            <Grid container my={2} justifyContent="space-between" alignItems={"flex-end"}>
              <Typography color="#777">
                새 약관을 추가하려면 복사하기 (
                <ContentCopyRoundedIcon color="#777" fontSize="small" />) 버튼을 클릭하세요.
              </Typography>
              <Typography variant="h6">{`총 ${pageInfo.total}개`}</Typography>
            </Grid>

            {isFetching || isLoading ? (
              <LoadingSkeleton />
            ) : (
              <>
                {/* 테이블 */}
                <Paper sx={tableWrapper} variant="outlined">
                  <TableContainer sx={{ height: "87%", maxHeight: "87%" }}>
                    <Table size="small" stickyHeader sx={tableHeader}>
                      <TableHead>
                        <TableRow>
                          {TERMS_COL.map((item) => {
                            return (
                              <TableCell
                                key={item.label}
                                sx={{
                                  width: item.size,
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {item.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data &&
                          data.termsList.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              role="checkbox"
                              tabIndex={-1}
                            >
                              {/* <TableCell padding="none" align="center">
                          <Checkbox
                            size="small"
                            color="primary"
                            // onClick={(event) => handleCheckClick(event, row)}
                            // checked={isSelected(row.companyIdx)}
                          />
                        </TableCell> */}
                              <TableCell padding="none" align="center">
                                {calcRowNum(index, pageInfo, perPage)}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleSetNowTerm(row)}
                              >
                                <Grid container justifyContent={"center"} columnGap={2} alignItems="center">
                                  <Typography variant="body2">{row.termsName}</Typography>
                                  <Tooltip title="약관복사" placement="top-start" arrow>
                                    <IconButton onClick={(e) => handleCopyTerm(e, row)} size="small">
                                      <ContentCopyRoundedIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </TableCell>
                              <TableCell padding="none" align="center">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectInfo(row);
                                    handleDeleteTerms();
                                  }}
                                  sx={{
                                    background: "#ffe8db",
                                    color: "#f97c34",
                                    "&>svg": {
                                      width: "1.1rem",
                                      height: "1.1rem",
                                    },
                                  }}
                                >
                                  <Trash2 />
                                </IconButton>
                              </TableCell>
                              <TableCell padding="none" align="center">
                                {dateFormat(row.createdAt)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container alignItems={"center"} justifyContent="center" my={2}>
                    <Pagination
                      count={pageInfo.totalPage}
                      page={pageInfo.pageNo}
                      onChange={(_, page) => {
                        setParamValues((prev) => ({
                          ...prev,
                          pageNo: page,
                        }));
                      }}
                    />
                  </Grid>
                </Paper>
              </>
            )}
          </Grid>
        )}

        {/* CONFIRM DELETE */}
        <Dialog maxWidth="xs" fullWidth open={isTermDelete} onClose={handleDeleteTerms}>
          <Suspense fallback={<LoadingCircle />}>
            <TermDeleteConfirmModal detail={selectInfo} onClose={handleDeleteTerms} />
          </Suspense>
        </Dialog>
      </Grid>
    </Box>
  );
}
