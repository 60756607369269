export const rgbaToObject = (input) => {
  const output = {};
  input
    .trim()
    .replace(/[a-zA-Z\(\)\;]/g, "")
    .split(",")
    .forEach((element, index) => {
      if (index === 0) {
        output.r = Number(element);
      } else if (index === 1) {
        output.g = Number(element);
      } else if (index === 2) {
        output.b = Number(element);
      } else {
        output.a = Number(element);
      }
    });
  return output;
};
export const objectToRgba = (input) => {
  return `rgba(${input.r},${input.g},${input.b},${input.a})`;
};
