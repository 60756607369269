import React, { useRef, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Link, useNavigate } from "react-router-dom";

import useMutationHook from "hooks/useMutationHook";

import { Controller, useForm } from "react-hook-form";

import LOGO from "assets/image/logo/ACG_CI-그레이2.png";
import BACKGROUND from "assets/image/background/acg_bg.png";
import { REQUIRE_VALUE } from "enums/errorMessage";
import Swal from "sweetalert2";
import { errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import { motion } from "framer-motion";
import { KeyRound, LogInIcon, Undo2, UserPlus } from "lucide-react";
import { warningIcon } from "../../../assets/icon/Icon_modal";

export default function LoginPage() {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adminAccount: "",
      adminPassword: "",
    },
  });

  useEffect(() => {
    localStorage.clear();
  }, []);

  const { mutate } = useMutationHook("login");
  const { mutate: login2faMutation } = useMutationHook("login2fa");
  const [showPassword, setShowPassword] = useState(false);
  const [login2fa, setLogin2fa] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [adminIdx, setAdminIdx] = useState(undefined);

  const authKey = useRef();

  const handleLogin = (data) => {
    mutate(
      {
        ...getValues(),
      },
      {
        onSuccess: (res) => {
          setAdminIdx(res.data.data);
          setLogin2fa(true);
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };
  const handleLogin2fa = () => {
    login2faMutation(
      {
        adminIdx: adminIdx,
        authKey: authKey.current.value,
      },
      {
        onSuccess: (res) => {
          setLogin2fa(undefined);
          localStorage.setItem("SUPER", res.data.data.loginToken);
          navigate("/company");
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };

  const handleCancelLogin2fa = () => setLogin2fa(false);

  const psRef = useRef(null);

  const handleKeyPress = (e, name) => {
    const values = getValues();
    if (e.key === "Enter") {
      if (name === "sendKey") {
        handleLogin(values);
        psRef.current.blur();
      } else if (name === "login") {
        handleLogin2fa();
      }
    }
  };

  const isMobile = useMediaQuery("(max-width:500px)");

  return (
    <Container maxWidth={false} disableGutters sx={bgContainer}>
      <Grid
        width={isMobile ? "25rem" : "30rem"}
        container
        p={4}
        justifyContent="center"
        bgcolor={"rgba( 255, 255, 255, 0.9 )"}
        boxShadow={"0 8px 32px 0 grey"}
        borderRadius={2}
        textAlign="center"
        border={"1px solid rgba( 255, 255, 255, 0.18 )"}
      >
        <form>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <Box
                component="img"
                sx={{
                  height: "4rem",
                  width: "8rem",
                  maxHeight: { xs: "4rem", md: 167 },
                  maxWidth: { xs: "8rem", md: 250 },
                }}
                alt="LOGO_Login"
                src={LOGO}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="adminAccount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: REQUIRE_VALUE,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={errors.adminAccount && true}
                    label={"아이디 입력"}
                    color={"neutral"}
                  />
                )}
              />
              <Typography color={"red"} variant="body2" textAlign={"right"}>
                {errors.adminAccount?.type === "required"
                  ? errors.adminAccount.message
                  : null}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel>비밀번호 입력</InputLabel>
                <Controller
                  name="adminPassword"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRE_VALUE,
                    },
                  }}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      type={showPassword ? "text" : "password"}
                      error={errors.adminPassword && true}
                      onKeyUp={(e) => handleKeyPress(e, "sendKey")}
                      inputRef={psRef}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="비밀번호 입력"
                    />
                  )}
                />
              </FormControl>
              <Typography color={"red"} variant="body2" textAlign={"right"}>
                {errors.adminPassword?.type === "required"
                  ? errors.adminPassword.message
                  : null}
              </Typography>
            </Grid>

            {login2fa ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4 }}
                style={{ width: "100%", marginTop: 20 }}
              >
                <Grid container columnGap={1}>
                  <Grid item xs>
                    <TextField
                      placeholder="인증번호를 입력해 주세요."
                      size="small"
                      fullWidth
                      inputRef={authKey}
                      onKeyUp={(e) => handleKeyPress(e, "login")}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{ height: "100%", color: "tomato" }}
                      onClick={handleCancelLogin2fa}
                      startIcon={<Undo2 size={15} color="tomato" />}
                    >
                      취소
                    </Button>
                  </Grid>
                </Grid>
              </motion.div>
            ) : (
              <Grid
                item
                container
                alignItems="center"
                justifyContent={"space-between"}
                columnGap={1}
              >
                <Grid item xs={5.5}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<UserPlus size={14} />}
                    onClick={() => {
                      Swal.fire(
                        alertModalConfig({
                          text: "ACG 담당자에게 문의해 주시기 바랍니다.",
                          title: "회원가입",
                          iconHtml: warningIcon,
                          confirmButtonText: "확인",
                        })
                      );
                    }}
                  >
                    회원가입
                  </Button>
                </Grid>
                <Grid item xs={5.5}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<KeyRound size={14} />}
                    onClick={() => {
                      Swal.fire(
                        alertModalConfig({
                          text: "ACG 담당자에게 문의해 주시기 바랍니다.",
                          title: "비밀번호 찾기",
                          iconHtml: warningIcon,
                          confirmButtonText: "확인",
                        })
                      );
                    }}
                  >
                    비밀번호 찾기
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {login2fa ? (
                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={handleLogin2fa}
                  sx={{ "&": { fontSize: 13, fontWeight: 700 } }}
                  startIcon={<LogInIcon size={15} />}
                >
                  로그인
                </Button>
              ) : (
                <Button
                  fullWidth
                  color={"secondary"}
                  variant={"contained"}
                  onClick={handleSubmit(handleLogin)}
                  sx={{ "&": { fontSize: 13, fontWeight: 700 } }}
                >
                  인증하기
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
}

const bgContainer = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `url(${BACKGROUND})  no-repeat center`,
  backgroundSize: "cover",
};
