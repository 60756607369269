import React, { lazy, useEffect } from "react";

import { useMediaQuery } from "@mui/material";

import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { isNavOpen } from "recoil/store";

import { userTypeQs } from "utils/userTypeQs";

const NavHR = lazy(() => import("./hr/NavHR"));
const NavSuper = lazy(() => import("./super/NavSuper"));

const NavComponent = () => {
  const setIsOpen = useSetRecoilState(isNavOpen);

  const matches = useMediaQuery("(max-width:500px)");
  const matchesMin = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    if (matches) {
      setIsOpen(false);
    }

    if (matchesMin) {
      setIsOpen(true);
    }
  }, [matches, matchesMin]);

  const location = useLocation();
  const userType = userTypeQs(location.pathname);

  return userType === "HR" ? <NavHR /> : <NavSuper />;
};

export default NavComponent;
