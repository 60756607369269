import React, { Suspense, useState, useEffect, lazy } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { useParams } from "react-router-dom";

import { useSetRecoilState } from "recoil";
import { practicePageDetailAtom } from "recoil/store";

import useGetQuery from "hooks/useGetQuery";

import { practiceType } from "utils/practiceType";
import { Button, Tooltip } from "@mui/material";

import katex from "katex";
import "katex/dist/katex.min.css";
import TopTitle from "components/templates/topTitle/TopTitle";
import { Plus } from "lucide-react";
window.katex = katex;

const CreatePracticeModal = lazy(() =>
  import("components/pages/acg_admin/practice/create/CreatePracticeModal")
);
const LoadingCircle = lazy(() =>
  import("components/UI/organism/LoadingCircle")
);
const LoadingSkeleton = lazy(() =>
  import("components/UI/organism/LoadingSkeleton")
);

const PopUpImage = lazy(() =>
  import("components/pages/acg_admin/practice/PopUpImage")
);

const Personal = lazy(() =>
  import("components/pages/acg_admin/practice/personal/Personal")
);
const Aptitude = lazy(() =>
  import("components/pages/acg_admin/practice/aptitude/Aptitude")
);
const None = lazy(() =>
  import("components/pages/acg_admin/practice/none/None")
);

let maxTabIndex = 1;
let currentTablIndex = 0;

function TabPanel(props) {
  const { children, tabId } = props;
  return (
    <Box
      value={maxTabIndex}
      index={maxTabIndex}
      // hidden={tabId !== currentTablIndex}
      key={maxTabIndex}
    >
      {children}
    </Box>
  );
}

const ExampleQuestionDetail = () => {
  const { id } = useParams();

  const practicePageDetail = useSetRecoilState(practicePageDetailAtom);

  const [popUpModalOn, setPopUpModalOn] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  const [tabId, setTabId] = useState(0);
  const [tabs, setAddTab] = useState([<Tab value={0} key={0} />]);
  const [createExampleQuestionModalOn, setCreateExampleQuestionModalOn] =
    useState(false);

  const handlePopUp = (_, img) => {
    setPopUpModalOn(!popUpModalOn);
    img !== "backdropClick" && setCurrentImg(img);
  };

  //* 1차정보 요청
  const [paramValues, setParamValues] = useState({
    practiceIdx: id,
  });

  //* 1차정보 요청 QUERY
  const { data, isLoading, isFetching } = useGetQuery(
    "practiceDetail",
    paramValues,
    true,
    false
  );

  const handleCreateExampleQuestionModal = () =>
    setCreateExampleQuestionModalOn(!createExampleQuestionModalOn);

  //* tab Render
  const tabRender = (data) => {
    //* 처음부터 등록된 문항이 하나도 없을때
    if (data.pageMaxNo) {
      return [...Array(parseInt(data.pageMaxNo))].map((n, index) => {
        return <Tab key={index} label={index + 1} value={index} />;
      });
    }
  };

  useEffect(() => {
    data && setAddTab(tabRender(data));
    data && practicePageDetail(data);
  }, [data]);

  const handleTabChange = (_, newTabId) => {
    if (newTabId === "tabProperties") {
      handleAddTab();
    } else {
      currentTablIndex = newTabId;
      setTabId(newTabId);
    }
  };

  const handleAddTab = () => {
    setAddTab([
      ...tabs,
      <Tab label={`${tabs.length + 1}`} key={tabs.length + 1} />,
    ]);
    setTabId(tabs.length);
    currentTablIndex = tabs.length;
  };

  const practiceRender = (data) => {
    if (practiceType(data) === "APTITUDE") {
      return <Aptitude tabId={tabId} />;
    } else {
      return <Personal tabId={tabId} />;
    }
  };

  if (data && data.pageMaxNo === null)
    return <None info={data} pageNo={tabId + 1} />;

  return (
    <Box display="flex" flexDirection="column" rowGap={2} height="100%">
      <TopTitle
        list={[
          {
            title: "예제문항 관리",
            url: `/practice`,
            current: false,
          },
          {
            title: `${data?.practiceName}`,
            url: `/practice/${id}`,
            current: true,
          },
        ]}
      />

      <Box
        bgcolor={"white"}
        borderRadius={2}
        p={1.5}
        py={2}
        display="flex"
        flexDirection="column"
      >
        <Grid container justifyContent={"space-between"} columnGap={1}>
          <Box>
            <Tabs
              variant="scrollable"
              value={tabId}
              onChange={handleTabChange}
              TabIndicatorProps={{ sx: { display: "none" } }}
              sx={tabStyle}
            >
              {tabs?.map((child) => child)}
              <Tab icon={<AddRoundedIcon />} value="tabProperties" />
            </Tabs>
          </Box>
          <Box>
            <Tooltip title="문항 추가" arrow placement="top">
              <Box component="span">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleCreateExampleQuestionModal}
                  startIcon={<Plus size={15} />}
                >
                  문항 추가
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Grid>
        <Grid overflow={"auto"}>
          <TabPanel tabId={tabId}>{data && practiceRender(data)}</TabPanel>
        </Grid>
        <Dialog
          maxWidth={
            data && data.likertYN === "N" && data.ipsativeYN === "N"
              ? "md"
              : "md"
          }
          fullWidth
          open={createExampleQuestionModalOn}
          onClose={handleCreateExampleQuestionModal}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <CreatePracticeModal
              onClose={handleCreateExampleQuestionModal}
              pageNo={tabId + 1}
              practiceType={{
                likertYN: data && data.likertYN,
                ipsativeYN: data && data.ipsativeYN,
                maxScale: data && data.maxScale,
                questionSetCnt: data && data.questionSetCnt,
              }}
              isEmpty={false}
            />
          </Suspense>
        </Dialog>
        <Dialog
          maxWidth={"md"}
          fullWidth
          open={popUpModalOn}
          onClose={handlePopUp}
        >
          <Suspense fallback={<LoadingCircle />}>
            <PopUpImage img={currentImg} />
          </Suspense>
        </Dialog>
      </Box>
    </Box>
  );
};

const tabStyle = {
  "&": {
    minHeight: "auto",
  },
  "& > .MuiTabs-scroller > .MuiTabs-flexContainer": {
    columnGap: 1,
  },
  "& > .MuiTabs-scroller > div> button": {
    minWidth: 8,
    minHeight: 8,
    border: "1px solid #777",
    borderRadius: 1,
    fontWeight: 700,
    px: 1.2,
    py: 0.5,
  },
  "& > .MuiTabs-scroller > div> .Mui-selected": {
    minWidth: 12,
    minHeight: 12,
    color: "#fff",
    background: "#1E283D",
    fontWeight: 700,
    border: "1px solid #1E283D",
  },

  "& > .MuiTabScrollButton-root.Mui-disabled": {
    display: "none",
  },
};

export default ExampleQuestionDetail;
