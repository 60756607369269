import { useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { Search } from "lucide-react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  groupSearchValueAtom,
  paginationAtom,
  searchGroupExamTypeAtom,
} from "recoil/store";

export default function SearchGroupList({ setParamValues }) {
  const setCurPage = useSetRecoilState(paginationAtom);
  const [searchValue, setSearchValue] = useRecoilState(groupSearchValueAtom);
  const [examType, setExamType] = useRecoilState(searchGroupExamTypeAtom);
  console.log("🚀 ~ searchValue:", searchValue);
  const searchRef = useRef();
  const submitSearch = () => {
    if (searchRef.current.value === "") {
      setCurPage(1);
      setExamType("TOTAL");
    }
    setSearchValue(searchRef.current.value);

    let searchContent = {
      groupName: searchRef.current.value,
      roomUseYN: examType,
    };

    let submit = { ...searchContent };

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (submit[key] === "TOTAL") delete submit[key];
    }

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (key === "") delete submit[""];
    }

    // 필터 미적용 항목 삭제
    for (const key in submit) {
      if (submit[key] === "") delete submit[key];
    }

    setParamValues({ pageNo: 1, ...submit });
  };
  const handleSubmitValues = (e) => {
    const { value } = e.target;

    setExamType(value);
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  return (
    <Grid container columnGap={1}>
      <TextField
        select
        label="감독 방식"
        size="small"
        name="roomUseYN"
        onChange={handleSubmitValues}
        value={examType}
        sx={{ minWidth: 100 }}
      >
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"Y"} value={"Y"}>
          화상감독
        </MenuItem>
        <MenuItem key={"N"} value={"N"}>
          무감독
        </MenuItem>
      </TextField>

      <Grid item>
        <TextField
          size="small"
          inputRef={searchRef}
          sx={{ minWidth: 300 }}
          defaultValue={searchValue}
          placeholder="공고명 또는 검사시작 날짜를 입력해주세요"
          onKeyDown={(e) => {
            if (e.key === "Enter") submitSearch();
          }}
        />
      </Grid>
      <Grid item>
        <Button
          startIcon={<Search size={15} />}
          sx={{ height: "100%" }}
          variant={"outlined"}
          onClick={submitSearch}
        >
          검색
        </Button>
      </Grid>
    </Grid>
  );
}
