import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { lazy, Suspense } from "react";
import { Box, Button, Checkbox, Dialog, Divider, Grid } from "@mui/material";
import { dateFormat } from "utils/formatter/dateFormat";
import { useState } from "react";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
const QnaReplyModal = lazy(() => import("../QnaReplyModal"));
const QnAListCard = ({ props }) => {
  const {
    adminName,
    category,
    createdAt,
    replySuccessYN,
    testerCell,
    testerEmail,
    testerName,
    text,
  } = props;

  const [replyModalOn, setReplyModalOn] = useState(false);
  const handleReplyModal = (row) => {
    setSelectedRow(row);
    setReplyModalOn(!replyModalOn);
  };

  const [selectedRow, setSelectedRow] = useState({});

  return (
    <Grid container flexWrap={"nowrap"}>
      <Accordion
        sx={{
          boxShadow: "none",
          border: "1px solid #cacaca",
          borderRadius: 1.5,
        }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container columnGap={1} justifyContent="space-between">
            <Checkbox
              size="small"
              sx={{ padding: 0 }}
              style={{ color: "#637381" }}
              // onChange={handleSelectAllClick}
            />
            <Grid display={"block"} width={2 / 4}>
              <Typography noWrap>{text}</Typography>
            </Grid>
            <Grid display={"block"} width={1 / 4}>
              <Typography noWrap>{testerName}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowGap={2} position={"relative"}>
            <Grid container justifyContent={"space-between"}>
              <Typography fontSize={12}>
                [{category}] {text}
              </Typography>
              <Typography
                fontSize={12}
                textAlign={"right"}
              >{`문의등록일 : ${dateFormat(createdAt)}`}</Typography>
            </Grid>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Typography fontSize={12}>{testerName}</Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "0 5px" }}
              />

              <Grid item>
                <Typography fontSize={12}>{testerCell}</Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "0 5px" }}
              />
              <Grid item>
                <Typography fontSize={12}>{testerEmail}</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography fontSize={12}>답변여부</Typography>
              <Typography fontSize={12}>
                {replySuccessYN} ({adminName})
              </Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleReplyModal(props)}
              >
                답변달기
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Q&A딥변 */}
      <Dialog
        open={replyModalOn}
        onClose={handleReplyModal}
        maxWidth="sm"
        fullWidth
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <QnaReplyModal detail={selectedRow} onClose={handleReplyModal} />
        </Suspense>
      </Dialog>
    </Grid>
  );
};

export default QnAListCard;
