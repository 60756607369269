import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

const LoadingCircle = () => {
  return (
    <Grid container justifyContent={"center"} alignItems="center" p={4}>
      <CircularProgress size={30} />
    </Grid>
  );
};

export default LoadingCircle;
