import React, { useState } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import LoadingCircle from "components/UI/organism/LoadingCircle";

import useGetQueryhr from "hooks/useGetQueryhr";

import _ from "lodash";
import NoImageID from "components/UI/NoImageID";

const IdCardCheckModal = ({ roomTesterInfo, onClose, pilotFlag = false }) => {
  const [paramValues, setParamValues] = useState({
    // type: "IMAGE",
    testerIdx: roomTesterInfo.testerIdx,
  });
  const { data, isLoading, isFetching, isError, refetch } = useGetQueryhr(
    "getTesterImageData",
    paramValues,
    true,
    pilotFlag
  );
  if (isLoading || isFetching) return <LoadingCircle />;
  return (
    <>
      <DialogTitle
        variant="h5"
        fontWeight="bold"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        응시자 신분증 확인
        <Typography component="span" fontWeight={700}>
          {`응시자 : ${roomTesterInfo.testerName}`}
        </Typography>
      </DialogTitle>

      {/* 신분증 이미지 */}
      <DialogContent sx={{ pb: 0 }}>
        {data &&
        data.idFileUrl === null &&
        data.preIdFileUrl === null &&
        data.preTesterScreenUrl === null ? (
          <EmptyPlaceholder />
        ) : (
          <Grid
            container
            justifyContent={"space-between"}
            columnGap={2}
            rowGap={2}
          >
            <Grid item xs>
              <Card variant="outlined">
                {data?.preTesterScreenUrl === null ? (
                  <NoImageID />
                ) : (
                  <CardMedia
                    sx={{ height: 250, border: "none", objectFit: "fill" }}
                    image={data?.preTesterScreenUrl}
                    component="img"
                  />
                )}

                <CardContent>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    textAlign="center"
                  >
                    응시자 본인확인 화면
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card variant="outlined">
                {data?.preIdFileUrl === null ? (
                  <NoImageID />
                ) : (
                  <CardMedia
                    sx={{ height: 250, border: "none", objectFit: "fill" }}
                    image={data?.preIdFileUrl || ""}
                    component="img"
                  />
                )}
                <CardContent>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    textAlign="center"
                  >
                    사전검사
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card variant="outlined">
                {data?.idFileUrl === null ? (
                  <NoImageID />
                ) : (
                  <CardMedia
                    sx={{
                      height: 250,
                      border: "none",
                      objectFit: "fill",
                    }}
                    image={data?.idFileUrl || ""}
                    component="img"
                  />
                )}

                <CardContent>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    textAlign="center"
                  >
                    본 검사
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3, pl: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </>
  );
};

export default IdCardCheckModal;
