import { useQuery } from "@tanstack/react-query";
import { checkIcon, errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getHandler } from "../utils/requestURI";

const useGetQuery = (name, paramValues, enabledOption, pilotFlag) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [pageInfo, setPageInfo] = useState({
    total: 0,
    pageNo: 1,
    totalPage: 0,
  });

  const { isLoading, isFetching, isError, refetch } = useQuery(
    [name, paramValues],
    (queryKey) => getHandler(name, queryKey, pilotFlag),
    {
      retry: 1,
      enabled: enabledOption,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setData(res.data.data);
        setPageInfo((prev) => ({
          ...prev,
          totalPage: res.data.data.totalPage,
          total: res.data.data.total,
          pageNo: res.data.data.pageNo,
        }));
      },
      onError: (err) => {
        if (err.response.status >= 400) {
          //* 권한오류
          if (
            err.response.data.message === "Unauthorized" ||
            err.response.data.message === "잘못된 토큰입니다."
          ) {
            Swal.fire(
              alertModalConfig({
                text: `유효하지 않은 토큰입니다. \n 다시 로그인 해주세요.`,
                title: "Error",
                iconHtml: errorIcon,
                confirmButtonText: "확인",
              })
            ).then((result) => {
              if (result.isConfirmed) navigate("/");
            });
          } else {
            Swal.fire(
              alertModalConfig({
                text: err.response.data.message,
                title: "Error",
                iconHtml: errorIcon,
                confirmButtonText: "확인",
              })
            );
          }
        }
      },
    }
  );

  return { data, pageInfo, isFetching, isLoading, isError, refetch };
};

export default useGetQuery;
