import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { ChromePicker } from "react-color";

export default function ColorPickerBtn({
  setSubmitValues,
  colors,
  name,
  isFrame,
  isBgColor,
  isFullWidth,
  defaultBgImage,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleColor = (e) => {
    setAnchorEl(e.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setDisplayColorPicker(false);
  };
  return (
    <>
      <Button
        onClick={handleColor}
        variant={"outlined"}
        fullWidth={isFullWidth ? true : false}
        disabled={isBgColor === true || isBgColor === undefined ? false : true}
      >
        <Grid container justifyContent={"space-between"} alignItems="center">
          {colors[name] !== "" ? (
            isFrame ? (
              <>
                <Box></Box>
                <Typography mr={1} variant="body2">
                  {`
                        ${colors.frameColor.r || 0},
                        ${colors.frameColor.g || 0},
                        ${colors.frameColor.b || 0},
                        ${colors.frameColor.a || 0}
                      `}
                </Typography>
                <Box
                  bgcolor={`rgba(${colors.frameColor?.r},${colors.frameColor?.g},${colors.frameColor?.b},${colors.frameColor?.a})`}
                  borderRadius={5}
                  width={18}
                  height={18}
                  border="0.3px solid black"
                />
              </>
            ) : (
              <>
                <Box></Box>
                <Typography variant="body2">
                  {colors[name] || "#FEFEFE"}
                </Typography>
                <Box
                  bgcolor={colors[name]}
                  borderRadius={5}
                  width={18}
                  height={18}
                  border="0.3px solid black"
                />
              </>
            )
          ) : null}
        </Grid>
      </Button>
      <Popover
        open={displayColorPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ChromePicker
          disableAlpha={isFrame ? false : true}
          color={colors[name]}
          onChange={(value) => {
            if (isFrame) {
              setSubmitValues((prev) => ({
                ...prev,
                [name]: value.rgb,
              }));
            } else {
              setSubmitValues((prev) => ({
                ...prev,
                [name]: value.hex,
              }));
            }
          }}
        />
      </Popover>
    </>
  );
}
