import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import "question.css";

import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import ImageResize from "quill-image-resize";
import { dateFormat } from "utils/formatter/dateFormat";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MESSAGE_UPDATE_PRACTICE, MESSAGE_UPLOAD } from "enums/modalMessage";
import { Collapse, OutlinedInput, Stack, Switch, Tooltip } from "@mui/material";
import { toImgURL } from "utils/extractImgURL";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import katex from "katex";
import "katex/dist/katex.min.css";
import { Check, Plus, Settings, Trash2, X } from "lucide-react";

const fontArray = [
  "9px",
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "18px",
  "19px",
  "20px",
  "21px",
  "22px",
  "23px",
  "24px",
];

window.katex = katex;

var Size = Quill.import("attributors/style/size");
Size.whitelist = fontArray;
Quill.register(Size, true);
//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);

Quill.register("modules/ImageResize", ImageResize);
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "image",
  "align",
  "script",
  "size",
  "formula",
];
const EditQuestionAptitude = ({
  targetItem,
  pageNo,
  practiceType,
  onClose,
}) => {
  console.log("targetItem: ", targetItem);
  const [questionSubText, setQuestionSubText] = useState(
    targetItem?.questionSubText
  );

  //* 보기 이미지 업로드
  const { mutate: upload_QuestionExImg } = useMutationHook(
    "upload_question_ExImg"
  );
  const questionEx = useRef();

  const imageHandler = (type) => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];

      if (type === "exList") {
        upload_QuestionExImg(
          {
            questionIdx: Number(id),
            questionNo: Number(questionNoRef.current.value),
            questionExNo: practiceExRegistList.length + 1,
            questionImg: file,
          },
          {
            onSuccess: (res) => {
              setQuestionSubText(res.data.data);
              const editor = questionEx.current.getEditor(); // 에디터 객체 가져오기
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
            },
          }
        );
      } else {
        upload_QuestionImg(
          {
            questionIdx: Number(id),
            questionNo: Number(questionNoRef.current.value),
            questionImg: file,
          },
          {
            onSuccess: (res) => {
              setQuestionSubText(res.data.data);
              const editor = questionSubTextRef.current.getEditor(); // 에디터 객체 가져오기
              const range = editor.getSelection(true);
              editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
            },
          }
        );
      }
    });
  };

  const questionTextModules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          [{ header: [1, 2, 3, false] }],
          [{ size: ["18px"] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
      },
    };
  }, []);

  // 지문
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          ["image"],
          [{ header: [1, 2, 3, false] }],
          [
            {
              size: fontArray,
            },
          ], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: () => imageHandler("question"),
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, []);

  // 보기
  const exListModules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          ["image", "formula"],
          [{ header: [1, 2, 3, false] }],
          [{ size: ["18px"] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: () => imageHandler("exList"),
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, []);
  // 위에서 설정한 모듈들 foramts을 설정한다

  const { id } = useParams();
  const { likertYN, ipsativeYN } = practiceType;
  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const questionSubTextRef = useRef();
  const questionTextRef = useRef();
  const questionNoRef = useRef();
  const [questionSubUseYN, setQuestionSubUseYN] = useState("Y");
  const [practiceExRegistList, setPracticeExRegistList] = useState([]);
  const [questionExLayout, setQuestionExLayout] = useState(1);
  const [targetItemIdx, setTargetItemIdx] = useState(0);
  const [보기, set보기] = useState("");
  const [answerScore, setAnswerScore] = useState(0);
  const [isUseScore, setIsUseScore] = useState(false);

  const handleIsUseScore = (event) => {
    setIsUseScore(event.target.checked);
  };

  //* 예제 등록 Mutation
  const { mutate } = useMutationHook("updateQuestionDetail");

  //* 문제 이미지  업로드
  const { mutate: upload_QuestionImg } = useMutationHook(
    "upload_question_QuestionImg"
  );
  const handleQuestionSubUse = (e) => {
    setQuestionSubUseYN(e.target.value);
  };

  const handleAnswerScore = (e) => {
    setAnswerScore(e.target.value);
  };
  const handleQuestionExLayout = (e) => {
    setQuestionExLayout(e.target.value);
  };
  useEffect(() => {
    if (targetItem) {
      setQuestionSubText(targetItem.questionSubText);
      setPracticeExRegistList(targetItem.questionExList);
      setQuestionSubUseYN(targetItem.questionSubUseYN);
      setQuestionExLayout(targetItem.questionExLayout);
    }
  }, []);

  const handleUpdate보기 = (_, item) => {
    // setAnswerScore(item.answerScore);
    setIsEditMode(!isEditMode);
    set보기(item.questionExText);
    setTargetItemIdx(item.questionExNo);
  };

  const handle보기 = (e) => {
    set보기(e);
  };

  const handleChange보기 = () => {
    setPracticeExRegistList((prev) => {
      const newObj = [...prev];
      newObj[targetItemIdx - 1] = {
        questionExText: 보기,
        questionExNo: targetItemIdx,
        answerScore: prev[targetItemIdx - 1].answerScore,
      };
      return [...newObj];
    });
    setIsEditMode(false);
    set보기("");
    // setAnswerScore(0);
  };
  const handleClickScore = (index) => {
    setPracticeExRegistList((prev) => {
      let newObj = [...prev];
      newObj[index] = {
        questionExText: prev[index].questionExText,
        questionExNo: index + 1,
        answerScore: prev[index].answerScore === 0 ? 1 : 0,
      };
      return [...newObj];
    });
  };

  const handleCustomScore = (e, index) => {
    const { value } = e.target;
    setPracticeExRegistList((prev) => {
      let newObj = [...prev];
      newObj[index] = {
        questionExText: prev[index].questionExText,
        questionExNo: index + 1,
        answerScore: Number(value) || 0,
      };
      return [...newObj];
    });
  };

  const questionExList = (practiceExRegistList = []) => {
    return practiceExRegistList?.map((n, index) => {
      return (
        // <Grid
        //   container
        //   columnGap={1}
        //   alignItems="center"
        //   justifyContent={"space-between"}
        //   key={n.questionExNo}
        // >
        //   <Grid item xs container columnGap={1} alignItems="center">
        //     <Typography component={"span"}>{index + 1}.</Typography>
        //     <ReactQuill
        //       theme="bubble"
        //       readOnly
        //       value={n?.questionExText}
        //       style={{ padding: 0 }}
        //       className="ql-readOnly"
        //     />
        //     <Typography component={"span"}>
        //       (배점 : {n.answerScore} 점)
        //     </Typography>
        //   </Grid>
        //   <Grid item>
        //     <Tooltip title="수정하기" arrow placement="top">
        //       <Box component="span">
        //         <IconButton
        //           variant="contained"
        //           size="small"
        //           sx={{
        //             background: "#fff7d5",
        //             color: "#ffd20a",
        //           }}
        //           onClick={(e) => handleUpdate보기(e, n)}
        //         >
        //           <EditIcon fontSize="small" />
        //         </IconButton>
        //       </Box>
        //     </Tooltip>
        //   </Grid>

        //   <Grid item>
        //     <Tooltip title="삭제하기" arrow placement="top">
        //       <Box component="span">
        //         <IconButton
        //           sx={{
        //             background: "#ffafaf",
        //             color: "#ff4444",
        //           }}
        //           size="small"
        //           variant="contained"
        //           onClick={(e) => handleRemoveRow(e, index)}
        //         >
        //           <RemoveRoundedIcon fontSize="small" />
        //         </IconButton>
        //       </Box>
        //     </Tooltip>
        //   </Grid>
        // </Grid>
        <Grid
          container
          columnGap={1}
          alignItems="center"
          justifyContent={"space-between"}
          key={n.questionExNo}
        >
          <Box>
            <Grid item container columnGap={1} alignItems="center">
              {/* <ExListNumberBtn index={index} onClick={handleClickScore(index + 1)} /> */}
              <Grid
                onClick={() => handleClickScore(index)}
                borderRadius={"50%"}
                border="1px solid black"
                width={"24px"}
                height={"24px"}
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  "&": {
                    background: n.answerScore >= 1 ? "black" : "white",
                    color: n.answerScore >= 1 ? "white" : "black",
                  },
                  "&:hover": {
                    boxShadow: "0px 0 0 0.2rem rgb(92 92 92/50%);",
                  },
                }}
              >
                <Typography fontSize="14px" fontWeight={500}>
                  {index + 1}
                </Typography>
              </Grid>

              <Typography
                m={0}
                fontSize="18px"
                sx={{ whiteSpace: "pre-wrap", "&>p": { m: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: n?.questionExText,
                }}
              ></Typography>
              <Collapse in={isUseScore} unmountOnExit>
                <Stack columnGap={1} direction="row" alignItems={"center"}>
                  <Typography>/ 배점 :</Typography>
                  <TextField
                    size="small"
                    variant="standard"
                    sx={{ maxWidth: 30, input: { textAlign: "center" } }}
                    onChange={(e) => handleCustomScore(e, index)}
                    value={practiceExRegistList[index].answerScore}
                  />
                  <Typography> 점</Typography>
                </Stack>
              </Collapse>
            </Grid>
          </Box>
          <Box>
            <Grid item container columnGap={1}>
              <Grid item>
                <Tooltip title="수정하기" arrow placement="top">
                  <Box component="span">
                    <IconButton
                      sx={{
                        color: "#424242",
                        border: "0.5px solid #b7b7b7",
                      }}
                      size="small"
                      onClick={(e) => handleUpdate보기(e, n)}
                    >
                      <Settings size={15} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="삭제하기" arrow placement="top">
                  <Box component="span">
                    <IconButton
                      sx={{
                        border: "0.5px solid #c42c08",
                        color: "#c42c08",
                      }}
                      color="removeButton"
                      size="small"
                      variant="contained"
                      onClick={(e) => handleRemoveRow(e, index)}
                    >
                      <Trash2 size={15} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      );
    });
  };

  const handleAddrow = (_) => {
    set보기("");
    // setAnswerScore(0);
    setPracticeExRegistList((prev) => [
      ...prev,
      {
        questionExText: 보기,
        questionExNo: prev[prev?.length - 1]?.questionExNo + 1 || 1,
        answerScore: answerScore,
      },
    ]);
  };

  const handleRemoveRow = (_, index) => {
    setPracticeExRegistList(
      practiceExRegistList.filter(
        (item) => item.questionExNo !== practiceExRegistList[index].questionExNo
      )
    );
  };

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleSubmit = () => {
    console.log("practiceExRegistList: ", practiceExRegistList);
    const submitValues = {
      pageNo: pageNo,
      likertYN: likertYN,
      ipsativeYN: ipsativeYN,
      questionTitleRegistInfo: {
        questionSubText:
          questionSubUseYN === "Y" ? questionSubTextRef.current.value : null,
        questionNo: Number(questionNoRef.current.value),
        questionText: questionTextRef.current.value,
        questionSubUseYN: questionSubUseYN,
        questionExLayout: Number(questionExLayout),
      },
      questionExRegistList: practiceExRegistList.map((item, index) => {
        return {
          questionExText: item.questionExText,
          questionExNo: index + 1,
          answerScore: Number(item.answerScore),
        };
      }),
    };

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_PRACTICE,
        title: "문항수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { questionIdx: Number(id), values: submitValues },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 수정이 완료되었습니다.",
                  title: "문항수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("questionDetail2");
              });
            },
          }
        );
      }
    });
  };

  return (
    <>
      <DialogTitle
        variant="h5"
        fontWeight="bold"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        문항수정
        <Typography component="span">
          {`수정일 : ${dateFormat(new Date())}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid rowGap={4} container mt={2} flexDirection="row">
          <Grid item xs={12} container columnGap={1}>
            <Grid item xs={1.2}>
              <Title>문항번호</Title>
              <TextField
                size="small"
                disabled
                type={"number"}
                name={"companyName"}
                inputRef={questionNoRef}
                defaultValue={targetItem?.questionNo}
                sx={{ "&>div>input": { px: 1.2, textAlign: "right" } }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Title>질문</Title>

            <ReactQuill
              ref={questionTextRef}
              modules={questionTextModules}
              formats={formats}
              className={"questionText"}
              defaultValue={targetItem?.questionText}
              style={{ border: "1px solid #dbdbdb", whiteSpace: "pre-wrap" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Title>지문사용</Title>

            <FormControl>
              <RadioGroup
                value={questionSubUseYN}
                onChange={handleQuestionSubUse}
                row
              >
                <FormControlLabel value="Y" control={<Radio />} label="예" />
                <FormControlLabel
                  value="N"
                  control={<Radio />}
                  label="아니오"
                />
              </RadioGroup>
            </FormControl>
            {questionSubUseYN === "Y" && (
              <Grid bgcolor={"#e9e9e99d"} borderRadius={2} p={1.5}>
                <Title>지문</Title>
                <ReactQuill
                  ref={questionSubTextRef}
                  modules={modules}
                  formats={formats}
                  defaultValue={questionSubText}
                  style={{
                    border: "1px solid #dbdbdb",
                    whiteSpace: "pre-wrap",
                  }}
                  className="questionSubUseYN"
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Title>보기 레이아웃</Title>
            <Grid container justifyContent="center">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  name={"roomUseYN"}
                  size="small"
                  sx={{ justifyContent: "space-evenly" }}
                  value={questionExLayout}
                  onChange={handleQuestionExLayout}
                >
                  <FormControlLabel value={1} control={<Radio />} label="1행" />
                  <FormControlLabel value={2} control={<Radio />} label="2행" />
                  <FormControlLabel value={3} control={<Radio />} label="3행" />
                  <FormControlLabel value={4} control={<Radio />} label="4행" />
                  <FormControlLabel value={5} control={<Radio />} label="5행" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              mb={1}
            >
              <Title>보기입력</Title>
              {!isEditMode && (
                // <Box>
                //   <Grid
                //     container
                //     columnGap={1}
                //     alignItems="center"
                //     justifyContent="flex-end"
                //   >
                //     <OutlinedInput
                //       type="number"
                //       size="small"
                //       startAdornment={
                //         <Typography width={"100%"}>배점:</Typography>
                //       }
                //       placeholder="00"
                //       endAdornment="점"
                //       sx={{ width: "50%" }}
                //       inputProps={{ style: { textAlign: "right" } }}
                //       value={answerScore}
                //       onChange={handleAnswerScore}
                //     />
                //     <Tooltip title="보기 추가" arrow placement="top">
                //       <Box component="span">
                //         <IconButton
                //           sx={{
                //             background: "#d9ecff",
                //           }}
                //           size="small"
                //           variant="contained"
                //           onClick={(e) => handleAddrow(e)}
                //         >
                //           <AddRoundedIcon fontSize="small" color="info" />
                //         </IconButton>
                //       </Box>
                //     </Tooltip>
                //   </Grid>
                // </Box>
                <Box>
                  <Stack columnGap={1} direction="row" alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isUseScore}
                          onChange={handleIsUseScore}
                        />
                      }
                      label="배점입력"
                      labelPlacement="start"
                    />

                    <Tooltip title="보기 추가" arrow placement="top">
                      <Box component="span">
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          onClick={(e) => handleAddrow(e)}
                          startIcon={<Plus size={12} />}
                        >
                          보기 추가
                        </Button>
                      </Box>
                    </Tooltip>
                  </Stack>
                </Box>
              )}
              {isEditMode && (
                // <Box>
                //   <Grid
                //     item
                //     container
                //     columnGap={1}
                //     justifyContent="flex-end"
                //     alignItems="center"
                //   >
                //     <OutlinedInput
                //       type="number"
                //       size="small"
                //       startAdornment={
                //         <Typography width={"100%"}>배점:</Typography>
                //       }
                //       placeholder="00"
                //       endAdornment="점"
                //       sx={{ width: "40%" }}
                //       inputProps={{ style: { textAlign: "right" } }}
                //       value={answerScore}
                //       onChange={handleAnswerScore}
                //     />
                //     <Tooltip title="수정하기" arrow placement="top">
                //       <Box component="span">
                //         <IconButton
                //           onClick={handleChange보기}
                //           size="small"
                //           sx={{
                //             background: "#c3e8c6",
                //             color: "#00a50b",
                //             "&>svg": {
                //               width: "1.2rem",
                //               height: "1.2rem",
                //             },
                //           }}
                //         >
                //           <CheckRoundedIcon />
                //         </IconButton>
                //       </Box>
                //     </Tooltip>
                //     <Tooltip title="취소" arrow placement="top">
                //       <Box component="span">
                //         <IconButton
                //           onClick={() => setIsEditMode(!isEditMode)}
                //           size="small"
                //           sx={{
                //             background: "#ffe4e4",
                //             color: "#ff4444",
                //             "&>svg": {
                //               width: "1.2rem",
                //               height: "1.2rem",
                //             },
                //           }}
                //         >
                //           <CloseRoundedIcon />
                //         </IconButton>
                //       </Box>
                //     </Tooltip>
                //   </Grid>
                // </Box>
                <Box>
                  <Grid
                    item
                    // xs={2}
                    container
                    columnGap={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Tooltip title="수정하기" arrow placement="top">
                      <Box component="span">
                        <Button
                          startIcon={<Check size={13} />}
                          variant="contained"
                          disableElevation
                          onClick={handleChange보기}
                          size="small"
                        >
                          수정
                        </Button>
                      </Box>
                    </Tooltip>
                    <Tooltip title="취소" arrow placement="top">
                      <Box component="span">
                        <Button
                          startIcon={<X size={13} />}
                          variant="contained"
                          disableElevation
                          color="removeButton"
                          onClick={() => setIsEditMode(!isEditMode)}
                          size="small"
                          sx={{ "&": { color: "#c42c08" } }}
                        >
                          닫기
                        </Button>
                      </Box>
                    </Tooltip>
                  </Grid>
                </Box>
              )}
            </Grid>
            <ReactQuill
              ref={questionEx}
              value={보기}
              onChange={handle보기}
              modules={exListModules}
              formats={formats}
              style={{ border: "1px solid #dbdbdb", whiteSpace: "pre-wrap" }}
              className="questionExText"
            />
            <Grid container rowGap={1} mt={1}>
              {questionExList(practiceExRegistList)}
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
};

export default EditQuestionAptitude;

const Title = (props) => (
  <Typography fontWeight={700} mb={0.5}>
    {props.children}
  </Typography>
);
