import {
  Accordion,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableHeader, tableWrapper } from "themes/theme";
import NavOpenBtn from "components/templates/NavOpenBtn";
import React from "react";
import { Link } from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopTitle from "components/templates/topTitle/TopTitle";

const OptionBtn = (props) => (
  <Button
    size="small"
    sx={{
      minWidth: "40%",
      minHeight: "100%",
      fontSize: 9,
    }}
    variant="outlined"
    disableElevation
    color={props.color}
  >
    {props.text}
  </Button>
);

const SubjectDetail = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" rowGap={2} height="100%">
        {/* <Grid container justifyContent={"space-between"} mb={3}>
          <Box display={"flex"} alignItems="center" gap={2}>
            <NavOpenBtn />
            <Breadcrumbs>
              <HomeRoundedIcon
                style={{ color: "#77777" }}
                sx={{ display: "flex", alignItems: "center" }}
              />
              <Link
                key={2}
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                <Typography variant="h5">
                  척도/역량 관리 – 인성검사 PART I
                </Typography>
              </Link>
            </Breadcrumbs>
          </Box>
        </Grid> */}

        <TopTitle
          list={[
            {
              title: "과목 관리",
              url: `/practice`,
              current: false,
            },
            {
              title: `과목정보를 불러오는 API`,
              // url: `/practice/${id}`,
              current: true,
            },
          ]}
        />

        <Box
          bgcolor="white"
          height="calc(97% - 20px)"
          borderRadius={2}
          p={2}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Grid container justifyContent={"flex-end"}>
            <IconButton
              sx={{
                background: "#d7e0e9",
                borderRadius: 2,
              }}
              size="small"
              variant="contained"
              //   onClick={handleCreateExampleQuestionModal}
            >
              <AddRoundedIcon color="info" />
            </IconButton>
          </Grid>

          {/* 아코디언 */}
          <Grid container flexDirection={"row"} mb={2}>
            <Accordion
              elevation={0}
              sx={{ bgcolor: "#d2e1ed", borderRadius: 2, width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  "&.Mui-expanded": { minHeight: 40 },
                  "&.Mui-expanded > div": { my: 2 },
                }}
              >
                <Typography color={"#496a84"} fontWeight={700}>
                  척도관리
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  bgcolor: "#fff",
                  border: "1px solid #d2e1ed",
                  borderRadius: 1,
                }}
              >
                <Box>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                    mb={1}
                  >
                    <Select size="small" value={1}>
                      <MenuItem value={1}>Normative</MenuItem>
                      <MenuItem value={2}>Ipsative</MenuItem>
                    </Select>

                    <Button variant="contained" disableElevation size="small">
                      다운로드
                    </Button>
                  </Grid>
                  <Paper sx={tableWrapper} variant={"outlined"}>
                    <TableContainer>
                      <Table stickyHeader sx={tableHeader} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>팍씨</TableCell>
                            <TableCell>구분</TableCell>
                            <TableCell>No</TableCell>
                            <TableCell>평균</TableCell>
                            <TableCell>표준편차</TableCell>
                            <TableCell>가중치</TableCell>
                            <TableCell>구성</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>N</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>감정조절</TableCell>
                            <TableCell>31.74778</TableCell>
                            <TableCell>4.34423</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>
                              <OptionBtn text="보기" />
                            </TableCell>
                          </TableRow>
                          {/* <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>감정조절</TableCell>
                          <TableCell>31.74778</TableCell>
                          <TableCell>4.34423</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>
                            <OptionBtn text="보기" />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>N</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>감정조절</TableCell>
                          <TableCell>31.74778</TableCell>
                          <TableCell>4.34423</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>
                            <OptionBtn text="보기" />
                          </TableCell>
                        </TableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid container flexDirection={"row"} mb={2}>
            <Accordion
              elevation={0}
              sx={{ bgcolor: "#d2e1ed", borderRadius: 2, width: "100%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  "&.Mui-expanded": { minHeight: 40 },
                  "&.Mui-expanded > div": { my: 2 },
                }}
              >
                <Typography color={"#496a84"} fontWeight={700}>
                  역량관리
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  bgcolor: "#fff",
                  border: "1px solid #d2e1ed",
                  borderRadius: 1,
                }}
              >
                <Box>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                    mb={1}
                  >
                    <Select size="small" value={1}>
                      <MenuItem value={1}>Normative</MenuItem>
                      <MenuItem value={2}>Ipsative</MenuItem>
                    </Select>

                    <Button variant="contained" disableElevation size="small">
                      다운로드
                    </Button>
                  </Grid>
                  <Paper sx={tableWrapper} variant={"outlined"}>
                    <TableContainer sx={{ height: "87%", maxHeight: "87%" }}>
                      <Table stickyHeader sx={tableHeader} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>팍씨</TableCell>
                            <TableCell>구분</TableCell>
                            <TableCell>No</TableCell>
                            <TableCell>평균</TableCell>
                            <TableCell>표준편차</TableCell>
                            <TableCell>가중치</TableCell>
                            <TableCell>구성</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>N</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>감정조절</TableCell>
                            <TableCell>31.74778</TableCell>
                            <TableCell>4.34423</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>
                              <OptionBtn text="보기" />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>N</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>감정조절</TableCell>
                            <TableCell>31.74778</TableCell>
                            <TableCell>4.34423</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>
                              <OptionBtn text="보기" />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>N</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>감정조절</TableCell>
                            <TableCell>31.74778</TableCell>
                            <TableCell>4.34423</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>
                              <OptionBtn text="보기" />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SubjectDetail;
