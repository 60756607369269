import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";

import { useRecoilValue } from "recoil";
import { companyIdxAtom } from "recoil/store";

import { useMutation } from "@tanstack/react-query";

import { theme } from "themes/theme";
import { postHandler } from "utils/requestURI";
import Swal from "sweetalert2";
import { checkIcon, errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";

export default function UploadBtn({
  isLogoUse,
  isBgColor,
  name,
  setImgSrc,
  info,
  setSubmitValues,
}) {
  const companyIdx = useRecoilValue(companyIdxAtom);

  const { mutate, data, isSuccess, isError } = useMutation(
    (submitValue) => {
      return postHandler(
        name === "logo" ? "uploadLOGO" : "uploadBgImage",
        submitValue
      );
    },
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          Swal.fire(
            alertModalConfig({
              title: "파일 업로드",
              text: res.data.message,
              iconHtml: checkIcon,
              confirmButtonText: "확인",
            })
          );
        }
      },
      onError: (error) => {
        if (error.response.status >= 400) {
          Swal.fire(
            alertModalConfig({
              title: "파일 업로드",
              text: error.response.data.message,
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        }
      },
    },
    { useErrorBoundary: (error) => console.log(error) }
  );

  const handleUpload = async (e, name) => {
    name === "logo"
      ? await mutate(
          {
            companyIdx: companyIdx,
            logo: e.target.files[0],
          },
          {
            onSuccess: (res) => {
              setSubmitValues((prev) => ({
                ...prev,
                ciFilePath: res.data.data,
              }));
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  title: "파일 업로드",
                  text: error.response.data.message,
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        )
      : await mutate(
          { companyIdx: companyIdx, bgImage: e.target.files[0] },
          {
            onSuccess: (res) => {
              setSubmitValues((prev) => ({
                ...prev,
                defaultBgURL: res.data.data,
              }));
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  title: "파일 업로드",
                  text: error.response.data.message,
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
  };

  const urlInfo = (info, name) => {
    if (name === "logo") return info.ciFilePath;
    else if (name === "bgImage") return info.bgColor;
  };

  const disableFlag = (info, name) => {
    if (name === "logo") return info.ciUseYN === "N" ? true : false;
    else if (name === "bgImage")
      return info.bgImageUseYN === "N" ? true : false;
  };

  return (
    <Grid width={"100%"}>
      <Button
        fullWidth
        disabled={disableFlag(info, name)}
        variant="outlined"
        component="label"
        sx={{ justifyContent: "space-between" }}
        children={
          <>
            <Typography maxWidth={90} noWrap variant="body2">
              {urlInfo(info, name)}
            </Typography>
            <Typography
              sx={{
                border:
                  isLogoUse === "Y" || isBgColor === false
                    ? "1px solid black"
                    : "1px solid #d2d2d2",
                borderRadius: "5px",
                padding: "0 7px",
                background:
                  isLogoUse === "Y" || isBgColor === false
                    ? theme.palette.primary.main
                    : grey[200],

                color:
                  isLogoUse === "Y" || isBgColor === false ? "white" : "grey",
              }}
            >
              업로드
            </Typography>
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => handleUpload(e, name)}
            />
          </>
        }
      />
      <Typography variant="body2" color={"#777"} textAlign="right">
        {name === "bgImage"
          ? "용량은 5MB로 제한됩니다."
          : "용량은 300KB로 제한됩니다."}
      </Typography>
    </Grid>
  );
}
