import React, { lazy } from "react";
import { modalAtom } from "recoil/store";
import { useRecoilValue } from "recoil";

const AlertDialog = lazy(() => import("./AlertDialog"));
const ConfirmDialog = lazy(() => import("./ConfirmDialog"));

const ParentDialog = () => {
  const modalInfo = useRecoilValue(modalAtom);

  if (modalInfo.type === "alert") return <AlertDialog />;
  else return <ConfirmDialog />;
};
export default ParentDialog;
