import React from "react";

import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const LoadingSkeleton = () => {
  return (
    <Grid flexDirection={"column"} alignItems="center" p={4}>
      <Skeleton height={30} />
      <Skeleton height={70} />
    </Grid>
  );
};

export default LoadingSkeleton;
