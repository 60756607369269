import React from "react";
import Box from "@mui/material/Box";
import NavOpenBtn from "components/templates/NavOpenBtn";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const TopTitle = ({ list }) => {
  return (
    <Box>
      <NavOpenBtn />
      <Breadcrumbs separator={<NavigateNextIcon />}>
        {list.map((item, index) => {
          return (
            <Link to={item.url} reloadDocument key={index}>
              <Typography
                variant="h5"
                fontWeight={item.current ? 700 : 500}
                color="#585757"
              >
                {item.title}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default TopTitle;
