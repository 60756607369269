import { Grid, Typography } from "@mui/material";
import React from "react";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
const NoImageID = () => {
  return (
    <Grid
      height={250}
      container
      justifyContent="center"
      alignItems="center"
      borderBottom={"0.5px solid #e0e0e0"}
      flexDirection="column"
      rowGap={2}
    >
      <ImageNotSupportedOutlinedIcon
        fontSize="large"
        style={{ color: "#e0e0e0" }}
      />
      <Typography color="#919191">등록된 이미지가 없습니다</Typography>
    </Grid>
  );
};

export default NoImageID;
