import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const NoneInfoByPage = () => {
  return (
    <Grid container alignItems={"center"} justifyContent="center" height="60vh">
      <Grid
        container
        bgcolor="#f4f4f4"
        width={"40%"}
        p={5}
        borderRadius={2}
        direction="column"
        alignItems={"center"}
        justifyContent="center"
      >
        <Typography variant="h5" fontWeight={700} mb={2} color={"#777"}>
          등록된 문항이 없습니다. 😮
        </Typography>
        <Typography variant="h6">
          '문항 추가' 버튼을 눌러 문항을 등록해주세요.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoneInfoByPage;
