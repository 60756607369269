import React, { useCallback, useRef } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useParams } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  modalAtom,
  questionPageDetailAtom,
  questionsDetailAtom,
} from "recoil/store";

import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";

const CreateOnePersonalQuestion = ({ pageNo, onClose }) => {
  const { ipsativeYN, likertYN, maxScale, questionSetCnt } =
    useRecoilValue(questionsDetailAtom);
  const details = useRecoilValue(questionPageDetailAtom);
  const queryClient = useQueryClient();
  const questionExNoRef = useRef([]);
  const questionNoRef = useRef();
  const questionTextRef = useRef();
  const questionSubTextRef = useRef();
  const { id } = useParams();

  //* 예제 등록 Mutation
  const { mutate } = useMutationHook("createQuestionDetail");

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleSubmit = () => {
    const submitValues = {
      questionTitleRegistInfo: {
        questionNo: Number(questionNoRef.current.value),
        questionText: questionTextRef.current.value,
        questionSubUseYN: likertYN === "Y" && ipsativeYN === "N" ? "N" : "Y",
        questionSubText:
          likertYN === "Y" && ipsativeYN === "N"
            ? null
            : questionSubTextRef.current.value,

        questionExLayout: 3,
      },
      questionExRegistList: questionExNoRef.current.map((item, index) => {
        return {
          questionExNo: index + 1,
          questionExText: item.value,
          answerScore: 0,
        };
      }),
      pageNo: pageNo,
      likertYN: likertYN,
      maxScale: maxScale,
      ipsativeYN: ipsativeYN,
      questionSetCnt: ipsativeYN === "Y" ? questionSetCnt : null,
    };
    console.log("submitValues: ", submitValues);

    Swal.fire(
      confirmModalConfig({
        text: "문항를 등록하시겠습니까?",
        title: "문항등록",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { values: submitValues, questionIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 등록이 완료되었습니다.",
                  title: "문항등록",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("questionDetail2");
              });
            },
          }
        );
      }
    });
  };

  return (
    <>
      <DialogContent>
        <Grid container direction="column" rowGap={2}>
          {(details.questionList === undefined ||
            details.questionList.length < 1) &&
            pageNo === 1 && (
              <Grid item xs container columnGap={1} direction="column">
                <Typography fontWeight={700}>응답척도</Typography>
                <Grid container justifyContent="space-between" columnGap={1}>
                  {[...Array(maxScale)].map((item, index) => {
                    return (
                      <Grid item xs>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="척도명을 입력하세요."
                          inputRef={(el) =>
                            (questionExNoRef.current[index] = el)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          <Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mb={1}
            >
              <Typography fontWeight={700}>문항내용</Typography>
            </Grid>
            <Grid container direction="column" rowGap={1}>
              <Grid container columnGap={1}>
                <Grid item xs={0.7}>
                  <TextField
                    size="small"
                    placeholder="번호"
                    name="questionNo"
                    inputRef={questionNoRef}
                    sx={{ "&>div>input": { textAlign: "center" } }}
                  />
                </Grid>
                {likertYN === "Y" && ipsativeYN === "N" ? null : (
                  <Grid item xs={0.7}>
                    <TextField
                      size="small"
                      placeholder="하위 문항번호"
                      name="questionSubText"
                      inputRef={questionSubTextRef}
                      sx={{ "&>div>input": { textAlign: "center" } }}
                    />
                  </Grid>
                )}
                <Grid item xs>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="문항내용을 입력하세요."
                    name="questionText"
                    inputRef={questionTextRef}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateOnePersonalQuestion;
