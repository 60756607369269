import { Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";

export const YNColor = (input) => {
  return input === "Y" ? (
    <Typography color={blue[500]}>Y</Typography>
  ) : (
    <Typography color={red[500]}>N</Typography>
  );
};
