import { Suspense, lazy, useEffect, useRef, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";
import { HRcompanyInfo } from "recoil/store";

import useMutationHook from "hooks/useMutationHook";

import { Controller, useForm } from "react-hook-form";

import BACKGROUND from "assets/image/background/acg_bg.png";
import LOGO from "assets/image/logo/ACG_CI-그레이2.png";
import { REQUIRE_VALUE } from "enums/errorMessage";
import { jwtDecoder } from "utils/jwtDecoder";

import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import Swal from "sweetalert2";

const ResetPasswordModal = lazy(() =>
  import("./passwordModal/ResetPasswordModal")
);

export default function LoginPage_hr() {
  const authKeyRef = useRef("");
  const navigate = useNavigate();

  const companyInfoAtom = useSetRecoilState(HRcompanyInfo);

  const [showPassword, setShowPassword] = useState(false);
  const [isSuccess1, setIsSuccess1] = useState(false);
  const [userIdx, setUserIdx] = useState();
  const [resetPasswordModalOn, setResetPasswordModalOn] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
      userPassword: "",
    },
  });

  //* 인증번호 요청
  const { mutate } = useMutationHook("loginHR", "HR");

  //* 인증번호 입력 후 로그인
  const { mutate: login } = useMutationHook("login", "HR");

  useEffect(() => {
    // removeCookie("ACG");
    sessionStorage.clear();
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSuccessLogin = (res) => {
    const userInfo = jwtDecoder(res.data.data.accessToken);
    sessionStorage.setItem(userInfo.companyCode, res.data.data.accessToken);
    companyInfoAtom(userInfo);
    return navigate(`/acghr_hr/${userInfo.companyCode}/group`);
  };

  const handleResetPasswordModal = () =>
    setResetPasswordModalOn(!resetPasswordModalOn);

  const isMobile = useMediaQuery("(max-width:500px)");

  const handleLogin = () => {
    login(
      {
        userIdx: userIdx,
        authKey: authKeyRef.current.value,
      },
      {
        onSuccess: (res) => {
          Swal.fire(
            alertModalConfig({
              text: "로그인되었습니다.",
              title: "로그인",
              iconHtml: questionIcon,
              confirmButtonText: "확인",
            })
          ).then((result) => {
            if (result.isConfirmed) {
              handleSuccessLogin(res);
            }
          });
        },
      }
    );
  };

  const handleAuth = async (data) => {
    mutate(
      {
        data,
      },
      {
        onSuccess: (res) => {
          console.log("res: ", res);
          if (
            res.data.message ===
            "30일 이내 동일 IP, 동일 기기로 로그인에 성공했습니다"
          ) {
            Swal.fire(
              alertModalConfig({
                text: "로그인되었습니다.",
                title: "로그인",
                iconHtml: checkIcon,
                confirmButtonText: "확인",
              })
            ).then((result) => {
              if (result.isConfirmed) handleSuccessLogin(res);
            });
          } else {
            setUserIdx(res.data.data.userIdx);
            setIsSuccess1(true);
          }
        },
      }
    );
  };

  const psRef = useRef(null);

  const handleKeyPress = (e, flag) => {
    const values = getValues();
    if (e.key === "Enter") {
      handleAuth(values);
      psRef.current.blur();
    }
  };

  return (
    <Container maxWidth={false} disableGutters sx={bgContainer}>
      <Grid
        width={isMobile ? "25rem" : "30rem"}
        container
        p={4}
        justifyContent="center"
        bgcolor={"rgba( 255, 255, 255, 0.9 )"}
        boxShadow={"0 8px 32px 0 grey"}
        borderRadius={2}
        textAlign="center"
        border={"1px solid rgba( 255, 255, 255, 0.18 )"}
      >
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Box
              component="img"
              sx={{
                height: "4rem",
                width: "8rem",
                maxHeight: { xs: "4rem", md: 167 },
                maxWidth: { xs: "8rem", md: 250 },
              }}
              alt="LOGO_Login"
              src={LOGO}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={700}>
              HR 담당자 로그인
            </Typography>
          </Grid>
          <Grid item xs>
            <form>
              <Grid item container flexDirection={"column"} rowGap={2}>
                <Grid item>
                  <Controller
                    name="userId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRE_VALUE,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"ID"}
                        color={"neutral"}
                        error={errors.userId && true}
                      />
                    )}
                  />
                  <Typography color={"red"} variant="body2" textAlign={"right"}>
                    {errors.userId?.type === "required"
                      ? errors.userId.message
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel>비밀번호 입력</InputLabel>

                    <Controller
                      name="userPassword"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRE_VALUE,
                        },
                      }}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          type={showPassword ? "text" : "password"}
                          error={errors.userPassword && true}
                          onKeyUp={handleKeyPress}
                          inputRef={psRef}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="비밀번호 입력"
                        />
                      )}
                    />
                    <Typography
                      color={"red"}
                      variant="body2"
                      textAlign={"right"}
                    >
                      {errors.userPassword?.type === "required"
                        ? errors.userPassword.message
                        : null}
                    </Typography>
                  </FormControl>
                </Grid>
                {isSuccess1 && (
                  <TextField
                    size="small"
                    sx={{ my: 1 }}
                    fullWidth
                    placeholder="인증키를 입력하세요."
                    inputRef={authKeyRef}
                  />
                )}

                <Grid item container xs={12} alignItems="center">
                  <Button
                    fullWidth
                    color={"secondary"}
                    variant={isSuccess1 ? "contained" : "outlined"}
                    onClick={
                      isSuccess1 ? handleLogin : handleSubmit(handleAuth)
                    }
                  >
                    {isSuccess1 ? "로그인" : "인증하기"}
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent={"center"}
                  columnGap={1}
                >
                  <Grid item xs={5.5}>
                    <Button
                      fullWidth
                      variant="none"
                      onClick={handleResetPasswordModal}
                    >
                      비밀번호 찾기
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={resetPasswordModalOn}
        onClose={handleResetPasswordModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <ResetPasswordModal onClose={handleResetPasswordModal} />
        </Suspense>
      </Dialog>
    </Container>
  );
}

const bgContainer = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `url(${BACKGROUND})  no-repeat center`,
  backgroundSize: "cover",
};
