import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as SearchIcon } from "assets/icon/searchIcon.svg";
import { ReactComponent as NoPerson } from "assets/icon/noPeopleIcon.svg";

const EmptyPlaceholder = ({ message = "데이터가 없습니다" }) => {
  return (
    <Stack alignItems="center" sx={{ my: 5 }}>
      {/* <Empty /> */}
      {message.includes("없습니다") ? (
        <NoPerson />
      ) : message === "검색할 응시자 정보를 입력해 주세요." ? (
        <SearchIcon />
      ) : null}
      <Typography color="#777" variant="h6">
        {message}
      </Typography>
    </Stack>
  );
};

export default EmptyPlaceholder;
