import React from "react";
import { DialogTitle, Typography } from "@mui/material";

import { dateFormat } from "utils/formatter/dateFormat";
import { practiceType } from "utils/practiceType";
import CreateOnePersonalQuestion from "./detail/personal/CreateOnePersonalQuestion";
import CreateOneQuestion from "./detail/aptitude/CreateOneQuestion";
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;
const CreateQuestionModal = ({ pageNo, onClose, data }) => {
  const renderByType = (data) => {
    if (practiceType(data) === "APTITUDE")
      return <CreateOneQuestion pageNo={pageNo} onClose={onClose} />;
    else return <CreateOnePersonalQuestion pageNo={pageNo} onClose={onClose} />;
  };
  return (
    <>
      <DialogTitle
        variant="h5"
        fontWeight="bold"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        문항등록
        <Typography component="span">
          {`등록일 : ${dateFormat(new Date())}`}
        </Typography>
      </DialogTitle>
      {renderByType(data)}
    </>
  );
};

export default CreateQuestionModal;
