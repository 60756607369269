import { useMutation } from "@tanstack/react-query";
import { errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import Swal from "sweetalert2";
import { postHandler } from "utils/requestURI";
import { hr_uris_POST } from "utils/uris/hr_uris";

const useMutationHook = (mutationName, type, pilotFlag) => {
  const { mutate, isLoading, isSuccess, mutateAsync } = useMutation(
    (param) => {
      if (type === "HR") return hr_uris_POST(mutationName, param, pilotFlag);
      else return postHandler(mutationName, param, pilotFlag);
    },
    {
      onError: (error) => {
        if (error.response.status >= 400) {
          const errMsg = error.response.data.message;
          Swal.fire(
            alertModalConfig({
              text: errMsg,
              title: "Error",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        }
      },
      onError: (error) => {
        Swal.fire(
          alertModalConfig({
            text: error.response.data.message,
            iconHtml: errorIcon,
            confirmButtonText: "확인",
          })
        );
      },
    }
  );

  return { mutate, isLoading, isSuccess, mutateAsync };
};

export default useMutationHook;
