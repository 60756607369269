import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from "react";

import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "question.css";
import { questionsDetailAtom } from "recoil/store";
import { useRecoilValue } from "recoil";

import useMutationHook from "hooks/useMutationHook";
import { useQueryClient } from "@tanstack/react-query";

import ImageResize from "quill-image-resize";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MESSAGE_CREATE_QUESTION_GROUP } from "enums/modalMessage";
import { toImgURL } from "utils/extractImgURL";
import { Collapse, Stack, Switch } from "@mui/material";
import Swal from "sweetalert2";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { Check, Plus, Settings, Trash2, X } from "lucide-react";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "image",
  "align",
  "script",
  "size",
  "formula",
];

Quill.register("modules/ImageResize", ImageResize);

const fontArray = [
  "9px",
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "18px",
  "19px",
  "20px",
  "21px",
  "22px",
  "23px",
  "24px",
];
var Size = Quill.import("attributors/style/size");
Size.whitelist = fontArray;
Quill.register(Size, true);

const Title = (props) => (
  <Typography fontWeight={700} mb={0.5} textAlign={props.isCenter && "center"}>
    {props.children}
  </Typography>
);
const CreateOneQuestion = ({ pageNo, onClose }) => {
  const { likertYN, ipsativeYN, questionIdx } =
    useRecoilValue(questionsDetailAtom);

  const questionEx = useRef();
  //* 문제 이미지  업로드
  const { mutate: upload_QuestionImg } = useMutationHook(
    "upload_question_QuestionImg"
  );

  //* 보기 이미지 업로드
  const { mutate: upload_QuestionExImg } = useMutationHook(
    "upload_question_ExImg"
  );

  const imageHandler = (type) => {
    console.log("에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!");

    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.
    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];

      if (type === "exList") {
        upload_QuestionExImg(
          {
            questionIdx: Number(questionIdx),
            questionNo: Number(questionNoRef.current.value),
            questionExNo: practiceExRegistList.length + 1,
            questionImg: file,
          },
          {
            onSuccess: (res) => {
              const editor = questionEx.current.getEditor(); // 에디터 객체 가져오기
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
            },
          }
        );
      } else {
        upload_QuestionImg(
          {
            questionIdx: Number(questionIdx),
            questionNo: questionNoRef.current.value,
            questionImg: file,
          },
          {
            onSuccess: (res) => {
              const editor = questionSubTextRef.current.getEditor(); // 에디터 객체 가져오기
              const range = editor.getSelection();
              editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
            },
          }
        );
      }
    });
  };
  const queryClient = useQueryClient();

  const [questionSubUseYN, setQuestionSubUseYN] = useState("Y");
  const [isEditMode, setIsEditMode] = useState(false);
  const [보기, set보기] = useState("");
  const [answerScore, setAnswerScore] = useState(0);
  const [questionExLayout, setQuestionExLayout] = useState(1);
  const [answerScoreIsError, setAnswerScoreIsError] = useState(false);
  const questionSubTextRef = useRef();
  const questionTextRef = useRef();
  const questionNoRef = useRef();
  const handleQuestionSubUse = (e) => {
    setQuestionSubUseYN(e.target.value);
  };
  const [targetItemIdx, setTargetItemIdx] = useState(0);

  const [practiceExRegistList, setPracticeExRegistList] = useState([]);

  const handleUpdate보기 = (_, item) => {
    setIsEditMode(!isEditMode);
    set보기(item.questionExText);
    setTargetItemIdx(item.questionExNo);
    setAnswerScore(item.answerScore);
  };

  const [isUseScore, setIsUseScore] = useState(false);

  const handleIsUseScore = (event) => {
    setIsUseScore(event.target.checked);
  };

  //* 예제 등록 Mutation
  const { mutate } = useMutationHook("createQuestionDetail");
  //* Mutation 후 이벤트
  const alertResult = useCallback((key, key2) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: key,
    });
    queryClient.invalidateQueries({
      queryKey: key2,
    });
  }, []);
  const handleAddRow = (_) => {
    set보기("");

    if (answerScore === "") {
      return setAnswerScoreIsError(true);
    }
    setAnswerScore(0);

    setPracticeExRegistList((prev) => [
      ...prev,
      {
        questionExText: 보기,
        questionExNo: prev[prev?.length - 1]?.questionExNo + 1 || 1,
        answerScore: answerScore,
      },
    ]);
  };
  const handleChange보기 = () => {
    setPracticeExRegistList((prev) => {
      const newObj = prev;
      newObj[targetItemIdx - 1] = {
        questionExText: 보기,
        questionExNo: targetItemIdx,
        answerScore: answerScore,
      };
      return newObj;
    });
    setIsEditMode(false);
    set보기("");
    setAnswerScore(0);
  };
  const handle보기 = (e) => {
    set보기(e);
  };

  const handleAnswerScore = (e) => {
    setAnswerScore(e.target.value);
  };

  // 지문
  const modulesQuestion = useMemo(() => {
    // console.log("input: ", input);
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          ["image"],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontArray }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: () => imageHandler("question"),
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, []);

  // 보기
  const modulesEx = useMemo(() => {
    // console.log("input: ", input);
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          ["image", "formula"],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: ["18px"] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: () => imageHandler("exList"),
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, []);

  // 질문
  const questionTextModules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ align: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: ["18px"] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ script: "sub" }, { script: "super" }],
        ],
      },
    };
  }, []);
  const handleRemoveRow = (_, index) => {
    setPracticeExRegistList(
      practiceExRegistList.filter(
        (item) => item.questionExNo !== practiceExRegistList[index].questionExNo
      )
    );
  };

  const handleQuestionExLayout = (e) => {
    setQuestionExLayout(e.target.value);
  };

  useEffect(() => {
    answerScore !== "" && setAnswerScoreIsError(false);
  }, [answerScore]);

  const handleSubmit = () => {
    const submitValues = {
      pageNo: pageNo,
      likertYN: likertYN,
      ipsativeYN: ipsativeYN,
      questionTitleRegistInfo: {
        questionNo: Number(questionNoRef.current.value),
        questionSubText:
          questionSubUseYN === "Y" ? questionSubTextRef.current.value : null,
        questionText: questionTextRef.current.value,
        questionSubUseYN: questionSubUseYN,
        questionExLayout: Number(questionExLayout),
      },
      questionExRegistList: practiceExRegistList.map((item, index) => {
        return {
          questionExText: item.questionExText,
          questionExNo: index + 1,
          answerScore: Number(item.answerScore),
        };
      }),
    };

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_CREATE_QUESTION_GROUP,
        title: "문항등록",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { questionIdx: Number(questionIdx), values: submitValues },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 등록이 완료되었습니다.",
                  title: "문항 등록",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed)
                  alertResult("questionDetail2", "questionDetail1");
              });
            },
          }
        );
      }
    });
  };

  const handleClickScore = (index) => {
    setPracticeExRegistList((prev) => {
      let newObj = prev;
      newObj[index] = {
        questionExText: prev[index].questionExText,
        questionExNo: index + 1,
        answerScore: prev[index].answerScore === 0 ? 1 : 0,
      };
      return [...newObj];
    });
  };

  const handleCustomScore = (e, index) => {
    const { value } = e.target;
    setPracticeExRegistList((prev) => {
      let newObj = prev;
      newObj[index] = {
        questionExText: prev[index].questionExText,
        questionExNo: index + 1,
        answerScore: Number(value),
      };
      return [...newObj];
    });
  };

  const questionExList = (practiceExRegistList) => {
    return practiceExRegistList.map((n, index) => {
      return (
        <Grid
          container
          columnGap={1}
          alignItems="center"
          justifyContent={"space-between"}
          key={n.questionExNo}
        >
          <Box>
            <Grid item container columnGap={1} alignItems="center">
              {/* <ExListNumberBtn index={index} onClick={handleClickScore(index + 1)} /> */}
              <Grid
                onClick={() => handleClickScore(index)}
                borderRadius={"50%"}
                border="1px solid black"
                width={"24px"}
                height={"24px"}
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  "&": {
                    background: n.answerScore >= 1 ? "black" : "white",
                    color: n.answerScore >= 1 ? "white" : "black",
                  },
                  "&:hover": {
                    boxShadow: "0px 0 0 0.2rem rgb(92 92 92/50%);",
                  },
                }}
              >
                <Typography fontSize="14px" fontWeight={500}>
                  {index + 1}
                </Typography>
              </Grid>

              <Typography
                m={0}
                p={0}
                fontSize="18px"
                sx={{ whiteSpace: "pre-wrap", "&>p": { m: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: n?.questionExText,
                }}
              ></Typography>
              <Collapse in={isUseScore} unmountOnExit>
                <Stack columnGap={1} direction="row" alignItems={"center"}>
                  <Typography>/ 배점 :</Typography>
                  <TextField
                    size="small"
                    variant="standard"
                    sx={{ maxWidth: 30, input: { textAlign: "center" } }}
                    onChange={(e) => handleCustomScore(e, index)}
                    value={practiceExRegistList[index].answerScore}
                  />
                  <Typography> 점</Typography>
                </Stack>
              </Collapse>
            </Grid>
          </Box>
          <Box>
            <Grid item container columnGap={1}>
              <Grid item>
                <Tooltip title="수정하기" arrow placement="top">
                  <Box component="span">
                    <IconButton
                      sx={{
                        color: "#424242",
                        border: "0.5px solid #b7b7b7",
                      }}
                      size="small"
                      onClick={(e) => handleUpdate보기(e, n)}
                    >
                      <Settings size={15} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="삭제하기" arrow placement="top">
                  <Box component="span">
                    <IconButton
                      sx={{
                        border: "0.5px solid #c42c08",
                        color: "#c42c08",
                      }}
                      color="removeButton"
                      size="small"
                      variant="contained"
                      onClick={(e) => handleRemoveRow(e, index)}
                    >
                      <Trash2 size={15} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      );
    });
  };

  return (
    <>
      <DialogContent>
        <Grid rowGap={2} container mt={2} flexDirection="column">
          <Grid item xs={12}>
            <Title>문항번호</Title>
            <TextField
              size="small"
              type={"number"}
              name={"companyName"}
              inputRef={questionNoRef}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              sx={{
                width: "10%",
                "&>div>input": { px: 1.2, textAlign: "right" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Title>질문</Title>

            <ReactQuill
              ref={questionTextRef}
              modules={questionTextModules}
              formats={formats}
              className={"questionText"}
              style={{ border: "1px solid #dbdbdb" }}
            />
            {/* <TextField fullWidth size="small" name={"companyName"} /> */}
          </Grid>
          <Grid item xs={12}>
            <Title>지문사용</Title>

            <FormControl>
              <RadioGroup
                value={questionSubUseYN}
                onChange={handleQuestionSubUse}
                row
              >
                <FormControlLabel value="Y" control={<Radio />} label="예" />
                <FormControlLabel
                  value="N"
                  control={<Radio />}
                  label="아니오"
                />
              </RadioGroup>
            </FormControl>
            {questionSubUseYN === "Y" && (
              <Grid bgcolor={"#e9e9e99d"} borderRadius={2} p={1.5}>
                <Title>지문</Title>

                <ReactQuill
                  ref={questionSubTextRef}
                  modules={modulesQuestion}
                  formats={formats}
                  className="questionSubUseYN"
                  style={{
                    border: "1px solid #dbdbdb",
                    whiteSpace: "pre-wrap",
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Title>보기 레이아웃</Title>
            <Grid container justifyContent="center">
              <FormControl fullWidth>
                <RadioGroup
                  row
                  name={"roomUseYN"}
                  size="small"
                  sx={{ justifyContent: "space-evenly" }}
                  value={questionExLayout}
                  onChange={handleQuestionExLayout}
                  // value={submitValues.roomUseYN}
                >
                  <FormControlLabel value={1} control={<Radio />} label="1행" />
                  <FormControlLabel value={2} control={<Radio />} label="2행" />
                  <FormControlLabel value={3} control={<Radio />} label="3행" />
                  <FormControlLabel value={4} control={<Radio />} label="4행" />
                  <FormControlLabel value={5} control={<Radio />} label="5행" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Box>
                <Title>보기입력</Title>
              </Box>

              {!isEditMode && (
                <Box>
                  <Stack columnGap={1} direction="row" alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isUseScore}
                          onChange={handleIsUseScore}
                        />
                      }
                      label="배점입력"
                      labelPlacement="start"
                    />

                    <Tooltip title="보기 추가" arrow placement="top">
                      <Box component="span">
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          onClick={(e) => handleAddRow(e)}
                          startIcon={<Plus size={12} />}
                        >
                          보기 추가
                        </Button>
                      </Box>
                    </Tooltip>
                  </Stack>
                </Box>
              )}

              {isEditMode && (
                <Box>
                  <Grid
                    item
                    // xs={2}
                    container
                    columnGap={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Tooltip title="수정하기" arrow placement="top">
                      <Box component="span">
                        <Button
                          startIcon={<Check size={13} />}
                          variant="contained"
                          disableElevation
                          onClick={handleChange보기}
                          size="small"
                        >
                          수정
                        </Button>
                      </Box>
                    </Tooltip>
                    <Tooltip title="취소" arrow placement="top">
                      <Box component="span">
                        <Button
                          startIcon={<X size={13} />}
                          variant="contained"
                          disableElevation
                          color="removeButton"
                          onClick={() => setIsEditMode(!isEditMode)}
                          size="small"
                          sx={{ "&": { color: "#c42c08" } }}
                        >
                          닫기
                        </Button>
                      </Box>
                    </Tooltip>
                  </Grid>
                </Box>
              )}
            </Stack>
            <ReactQuill
              ref={questionEx}
              value={보기}
              onChange={handle보기}
              modules={modulesEx}
              formats={formats}
              style={{ border: "1px solid #dbdbdb", position: "relative" }}
              className="questionExText"
              theme="snow"
              // value={questionImg}
            />

            <Grid container rowGap={1} mt={1} flexDirection="column">
              {questionExList(practiceExRegistList)}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 1 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateOneQuestion;
