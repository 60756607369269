import React from "react";

import Box from "@mui/material/Box";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";

import { isNavOpen } from "recoil/store";
import { useRecoilState } from "recoil";

const NavOpenBtn = () => {
  const [isOpen, setIsOpen] = useRecoilState(isNavOpen);
  const handleNav = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {isOpen ? (
        <Box
          width={30}
          height={35}
          bgcolor={"rgba(233, 233, 233, 1)"}
          borderRadius={2}
          position="absolute"
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          top={45}
          left={10}
          zIndex={2}
          sx={{
            cursor: "pointer",
          }}
          onClick={handleNav}
        >
          <MenuOpenRoundedIcon style={{ color: "grey" }} />
        </Box>
      ) : (
        <Box
          width={30}
          height={35}
          bgcolor={"rgba(233, 233, 233, 1)"}
          borderRadius={2}
          position="absolute"
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          top={45}
          left={10}
          zIndex={2}
          sx={{
            cursor: "pointer",
          }}
          onClick={handleNav}
        >
          <MenuRoundedIcon fontSize="medium" style={{ color: "grey" }} />
        </Box>
      )}
    </>
  );
};

export default NavOpenBtn;
