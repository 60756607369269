import CompanyList from "components/pages/acg_admin/company/CompanyList";
import GroupList from "components/pages/acg_hr/group/main/GroupList";
import RoomList from "components/pages/acg_hr/group/main/roomList/RoomList";
import RoomTesterList from "components/pages/acg_hr/group/main/roomList/RoomTesterList";
import QnAList from "components/pages/acg_admin/QnA/QnAList";
import TesterList from "components/pages/acg_hr/group/main/testerList/TesterList";
import TemplateMain from "components/pages/acg_admin/company/modules/layoutDesign/TemplateMain";
import ManageTerms from "components/pages/acg_admin/terms/ManageTerms";
import HRManage from "components/pages/acg_hr/hr/HRManage";
import UpdateGroup from "components/pages/acg_hr/group/update/UpdateGroup";

export const qnaRoutes = [
  { path: "/acghr_hr/:company/qna", element: <QnAList /> },
];
export const groupRoutes = [
  { path: "/acghr_hr/:company/group", element: <GroupList /> },
  { path: "/acghr_hr/:company/roomList:id", element: <RoomList /> },
  {
    path: "/acghr_hr/:company/roomList/testerList:id",
    element: <RoomTesterList />,
  },
  {
    path: "/acghr_hr/:company/update/:id",
    element: <UpdateGroup />,
  },
  { path: "/acghr_hr/:company/testerList:id", element: <TesterList /> },
];

export const HRManageRoutes = [
  { path: "/acghr_hr/:company/manager", element: <HRManage /> },
];

export const companyRoutes = [
  { path: "/company", element: <CompanyList /> },
  { path: "/company/layout", element: <TemplateMain /> },
  { path: "/company/terms", element: <ManageTerms /> },
];

// /login?232323
