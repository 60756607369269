import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import RoomTesterListCard from "./roomTesterList_mobile/RoomTesterListCard";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import LoadingCircle from "components/UI/organism/LoadingCircle";

import { companyInfoAtom } from "recoil/store";
import { useRecoilValue } from "recoil";

import { useQueryClient } from "@tanstack/react-query";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { GROUP_ROOM_TESTER_LIST } from "enums/tableColumn";
import { roomTesterListTimeFormat } from "utils/formatter/dateFormat";
import { tableHeader, tableWrapper } from "themes/theme";
import { checkboxSelect } from "utils/checkboxSelect";
import { YNColor } from "utils/formatter/YNColor";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import { examStatusTag } from "utils/tagRender";
import {
  MESSAGE_DELETE_TESTER,
  MESSAGE_MOVE_MANAGER_PAGE,
  MESSAGE_MOVE_TESTER,
  MESSAGE_UPDATE_TESTER_STATUS,
} from "enums/modalMessage";
import { Alert, Snackbar, Stack, TableContainer, Tooltip } from "@mui/material";
import MenuComponent from "components/UI/menu/MenuComponent";
import Swal from "sweetalert2";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import TopTitle from "components/templates/topTitle/TopTitle";
import { ExternalLink, Trash2, UserCheck } from "lucide-react";
const COPY_SUCCESS_MESSAGE = "클립보드에 복사되었습니다.";
const COPY_FAIL_MESSAGE = "클립보드에 복사를 실패하였습니다.";
const Idcardcheckmodal = lazy(() => import("./testerMenu/IdCardCheckModal"));
const UpdateRoomTesterModal = lazy(() => import("./testerMenu/UpdateRoomTesterModal"));
const UpdateTestStatusModal = lazy(() => import("./testerMenu/UpdateTestStatusModal"));
const TesterLogDataModal = lazy(() => import("./testerMenu/TesterLogDataModal"));
const TesterChatLogModal = lazy(() => import("./testerMenu/TesterChatLogModal"));
const TesterVideoLogModal = lazy(() => import("./testerMenu/TesterVideoLogModal"));
export default function RoomTesterList() {
  const { id, company } = useParams(); //*RoomIdx

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const location = useLocation();

  const [callStatusList, setCallStatusList] = useState([]);
  const queryClient = useQueryClient();

  //* MODAL STATUS
  const [updateRoomTesterModalOn, setUpdateRoomTesterModalOn] = useState(false);
  const [updateTestStatusModal, setUpdateTestStatusModal] = useState(false);
  const [logDataModal, setLogDataModal] = useState(false);
  const [idCardCheckModal, setIdCardCheckModal] = useState(false);
  const [testerChatLogModal, setTesterChatLogModal] = useState(false);
  const [testerVideoLogModal, setTesterVideoLogModal] = useState(false);
  const open = Boolean(anchorEl);
  const [paramValues, setParamValues] = useState({
    page: { totalGroup: 0, pageNo: 1, totalPage: 0 },
    // input: defaultFilterValues,
  });
  const { platform } = useRecoilValue(companyInfoAtom);

  //* 선택된 응시자 항목 정보
  const [roomTesterInfo, setRoomTesterInfo] = useState({});
  const [selectRoomIdx, setSelectRoomIdx] = useState("");

  const [showTester, setShowTester] = useState(null);

  useEffect(() => {
    if (location.state) {
      setParamValues((prev) => ({
        ...prev,
        pageNo: location.state.pageNo,
        //* 응시자 검색으로 들어올 시 total정보가 없으므로 50으로 고정(변동가능)
        perPage: location.state.total,
      }));

      setShowTester(location.state.testerIdx);
    }
  }, [location]);

  //* 룸 응시자 목록 조회
  const { data, pageInfo, isLoading, isFetching, isError, refetch } = useGetQueryhr(
    "roomTesterList",
    {
      ...paramValues,
      ...{ index: Number(id) },
    },
    true,
    false
  );

  const [triggerMoverRoomList, setTriggerMoverRoomList] = useState(false);

  //* 이동가능한 룸 목록 조회
  const {
    data: moveRoomList,
    isLoading: moveRoomListIsLoading,
    isFetching: moveRoomListIsFetching,
    isError: moveRoomListIsError,
    refetch: moveRoomListRefetch,
  } = useGetQueryhr(
    "moveRoomList",
    {
      ...paramValues,
      ...{
        groupIdx: location.state?.groupIdx,
        testerIdxs: selected.toString(),
        roomIdx: Number(id),
      },
    },
    triggerMoverRoomList,
    false
  );

  //* 전화상태 조회
  // const {
  //   data: callStatus,
  //   pageInfo: callStatusPageInfo,
  //   isLoading: callStatusIsLoading,
  //   isFetching: callStatusIsFetching,
  //   isError: callStatusIsError,
  //   refetch: callStatusRefetch,
  // } = useGetQueryhr("getCallStatus", {
  //   index: Number(id),
  // });

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    setSelected([]);
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  //* 감독관 페이지 이동
  const { mutate: moveToSupervisor } = useMutationHook("moveToSupervisor", "HR");

  //* 일괄전화요청
  const { mutate: callAll } = useMutationHook("callAll", "HR");

  //* 룸 이동시키기
  const { mutate: moveRoomMutate } = useMutationHook("moveRoom", "HR");

  //* 응시자 삭재
  const { mutate: deleteTester } = useMutationHook("deleteTesters", "HR");

  //* 응시자 사전점검 상태 변경
  const { mutate: updatePreExamInfo } = useMutationHook("updatePreExamInfo", "HR");

  //* CheckBox
  const handleCheckClick = (_, name) => {
    const { testerIdx } = name;
    const selectedIndex = selected.indexOf(testerIdx);
    setSelected(checkboxSelect(selected, selectedIndex, testerIdx));
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.testerIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  //* MODAL
  const handleUpdateRoomTesterModalOn = (_, reason) => {
    setUpdateRoomTesterModalOn((prev) => !prev);
  };
  const handleUpdateTestStatusModalOn = (_, reason) => {
    setUpdateTestStatusModal((prev) => !prev);
  };
  const handleLogDataModalOn = (_, reason) => {
    setLogDataModal((prev) => !prev);
  };
  const handleIdCardCheckModalOn = (_, reason) => {
    setIdCardCheckModal((prev) => !prev);
  };
  const handleTesterChatLogModalOn = (_, reason) => {
    setTesterChatLogModal((prev) => !prev);
  };
  const handleTesterVideoLogModal = (_, reason) => {
    setTesterVideoLogModal((prev) => !prev);
  };

  const modalGroup = (num, row) => {
    setRoomTesterInfo(row);
    if (num === 1) handleUpdateRoomTesterModalOn();
    if (num === 2) handleUpdateTestStatusModalOn();
    if (num === 3) handleIdCardCheckModalOn();
    if (num === 4) handleLogDataModalOn();
    if (num === 5) handleTesterChatLogModalOn();
    if (num === 6) handleTesterVideoLogModal();
    if (num === 7) alert("준비중입니다");
  };

  const handleSelectRoom = (e, row) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_MOVE_TESTER,
        title: "화상룸 이동",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        moveRoomMutate(
          {
            roomIdx: e.target.value,
            testerIdxs: selected.toString(),
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "룸 이동이 완료되었습니다.",
                  title: "화상룸 이동",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  setSelected([]);
                  queryClient.invalidateQueries({
                    queryKey: ["roomTesterList"],
                  });
                }
              });
            },
          }
        );
      }
    });
  };

  const moreList = useCallback(
    (row) => [
      {
        function: () => modalGroup(1, row),
        title: "응시자 정보 수정",
      },
      {
        function: () => modalGroup(2, row),
        title: "응시 현황 수정",
      },
      {
        function: () => modalGroup(3, row),
        title: "신분증 확인",
      },
      {
        function: () => modalGroup(4, row),
        title: "접속내역 확인",
      },
      {
        function: () => modalGroup(6, row),
        title: "녹화영상 확인",
      },
      {
        function: () => modalGroup(5, row),
        title: "채팅내용 확인",
      },
      {
        function: () => modalGroup(7, row),
        title: "개인 Report 확인",
      },
    ],
    [roomTesterInfo]
  );

  const handleMoveToAdvisor = () =>
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_MOVE_MANAGER_PAGE,
        title: "감독관 페이지 이동",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        moveToSupervisor(
          { roomIdx: Number(id) },
          {
            onSuccess: (res) => {
              window.open(
                `${process.env.REACT_APP_MANAGER}/${platform}/Login/?loginRoomIDX=${id}&loginAuthKey=${res.data.data.authKey}`,
                "_blank"
              );
            },
          }
        );
      }
    });

  const handleDeleteTester = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_TESTER,
        title: "응시자 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteTester(
          {
            testerIdx: selected,
            groupIdx: location.state?.groupIdx,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 삭제가 완료되었습니다.",
                  title: "응시자 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomTesterList");
              });
            },
          }
        );
      }
    });
  };

  const handleUpdatePreExamInfo = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_TESTER_STATUS,
        title: "사전점검 확인",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        updatePreExamInfo(
          {
            selected,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "해당 응시자 사전점검 상태가 '완료'로 변경되었습니다.",
                  title: "사전점검 확인",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomTesterList");
              });
            },
          }
        );
      }
    });
  };

  // const handleCallAll = () =>
  //   setModalOn({
  //     modalState: !modalOn.modalState,
  //     title: "일괄 전화요청",
  //     subTitle: MESSAGE_CALL_ALL,
  //     type: "confirm",
  //     status: "warning",
  //     modalFunc: () =>
  //       callAll(
  //         { roomIdx: Number(id) },
  //         {
  //           onSuccess: (res) => {
  //             setModalOn({
  //               modalState: !modalOn.modalState,
  //               title: "일괄 전화요청",
  //               type: "alert",
  //               subTitle: "일괄 전화요청이 진행됩니다.",
  //               status: "success",
  //             });
  //           },
  //         }
  //       ),
  //   });
  const [copyResult, setCopyResult] = useState(false);
  const [isCopyError, setIsCopyError] = useState(false);
  const [copyResultMessage, setCopyResultMessage] = useState("");
  const handleCloseAlert = () => setCopyResult(!copyResult);

  const handleTriggerGetRoomList = () => setTriggerMoverRoomList(true);
  const handleCopyClipBoard = async (text) => {
    const { companyName, testerName, testerBirth, testerCell, roomNo } = text;

    const copyText = `${companyName} / ${roomNo}번룸 /  ${testerName} / ${testerBirth} / ${testerCell}`;

    try {
      setCopyResult(true);
      await navigator.clipboard.writeText(copyText);
      setCopyResultMessage(COPY_SUCCESS_MESSAGE);
    } catch (e) {
      setIsCopyError(true);
      setCopyResultMessage(COPY_FAIL_MESSAGE);
    }
  };
  //* 통화상태
  const showStatus = (row) => {
    // const result =
    //   callStatus && callStatus.find((item) => item.testerIdx === row.testerIdx);
    // const callResult = result?.callStatus.status;
    // const callDuration = result?.callStatus.duration;
    // if (callStatusIsLoading || callStatusIsFetching)
    //   return <CircularProgress size={13} />;
    // if (callStatusIsError)
    //   return (
    //     <Typography sx={{ color: "grey" }} variant="button">
    //       미발신
    //     </Typography>
    //   );
    // return (
    //   <>
    //     <Typography
    //       fontSize="inherit"
    //       color={callResult === "부재중" ? red[500] : blue[700]}
    //     >{callResult}</Typography>
    //     {callDuration && <Typography  fontSize="inherit" >{`${callDuration}초`}</Typography>}
    //   </>
    // );
  };

  useEffect(() => {
    selected.length === 0 && setSelectRoomIdx("");
  }, [selected]);

  const refreshContent = () => {
    navigate(`/acghr_hr/${company}/roomList/testerList${id}`);
    navigate(0);
  };

  const handleRefresh = () => {
    refetch();
  };

  return (
    <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
      <Grid container justifyContent={"space-between"} mb={1}>
        <TopTitle
          list={[
            {
              title: "채용그룹 관리",
              url: `/acghr_hr/${company}/group`,
              current: false,
            },
            {
              title: "화상룸 목록",
              url: `/acghr_hr/${company}/roomList${location.state.groupIdx}`,
              current: false,
            },
            {
              title: `응시자리스트 / ${data?.data[0]?.roomNo || location.state?.roomNo || 0}번 룸`,
              url: `/acghr_hr/${company}/roomList/testerList${id}`,
              current: true,
            },
          ]}
        />
      </Grid>

      {isFetching || isLoading ? (
        <LoadingSkeleton />
      ) : data && data.data.length < 1 ? (
        <Paper
          sx={{
            ...tableWrapper,
            overflowY: "hidden",
            height: "100%",
          }}
          variant="outlined"
        >
          <Grid container justifyContent={"center"} alignItems="center" height={"100%"}>
            <EmptyPlaceholder />
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              p: 2,
              rowGap: 2,
            }}
            variant="outlined"
          >
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Box>
                <Typography fontSize={"1.1rem"} fontWeight={700}>{`응시자: ${pageInfo.total}명`}</Typography>
              </Box>
              <Box>
                <Stack direction={"row"} columnGap={1}>
                  {location.state?.roomNo !== 0 ? null : (
                    <Button
                      disabled={selected.length === 0 ? true : false}
                      variant="outlined"
                      onClick={handleUpdatePreExamInfo}
                      startIcon={<UserCheck size={15} />}
                    >
                      사전점검 확인
                    </Button>
                  )}
                  {/* <Tooltip title={"새로고침"} arrow placement="top">
                        <IconButton
                          size="small"
                          onClick={handleRefresh}
                          sx={{ border: "1px solid #8e939e", borderRadius: 1 }}
                        >
                          <RefreshRoundedIcon />
                        </IconButton>
                      </Tooltip> */}
                  <TextField
                    select
                    sx={{
                      background: "white",
                      borderRadius: "7px",
                      minWidth: 130,
                    }}
                    size="small"
                    label="룸 선택 및 이동"
                    disabled={selected.length === 0 ? true : false}
                    onFocus={handleTriggerGetRoomList}
                    onChange={handleSelectRoom}
                    value={selectRoomIdx}
                  >
                    {moveRoomList ? (
                      moveRoomList.map((item, index) => (
                        <MenuItem key={item.roomIdx} value={item.roomIdx}>
                          {`${item.roomNo}번 룸`}
                        </MenuItem>
                      ))
                    ) : (
                      <Box />
                    )}
                  </TextField>

                  {location.state?.roomNo === 0 ? null : (
                    <Button startIcon={<ExternalLink size={15} />} variant="outlined" onClick={handleMoveToAdvisor}>
                      감독관 페이지 이동
                    </Button>
                  )}
                  <Button
                    startIcon={<Trash2 size={15} />}
                    variant="contained"
                    color="removeButton"
                    disableElevation
                    sx={{ "&": { color: "#c42c08" } }}
                    onClick={handleDeleteTester}
                    disabled={selected.length <= 0 ? true : false}
                  >
                    응시자 삭제
                  </Button>
                </Stack>
              </Box>
            </Stack>
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" sx={{ width: 30 }}>
                      <Checkbox size="small" style={{ color: "#637381" }} onChange={handleSelectAllClick} />
                    </TableCell>
                    {GROUP_ROOM_TESTER_LIST.map((item) => (
                      <TableCell
                        key={item.label}
                        sx={{ width: item.size, fontWeight: "bold" }}
                        align="center"
                        padding="none"
                      >
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.data.map((row, index) => (
                      <TableRow
                        hover
                        key={row.testerIdx}
                        sx={{
                          "&": { height: 43 },
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        tabIndex={-1}
                        className={row.testerIdx === showTester ? "roomTesterFocus" : ""}
                      >
                        <TableCell align="center" padding="none">
                          <Checkbox
                            size="small"
                            color="primary"
                            onClick={(event) => handleCheckClick(event, row)}
                            checked={isSelected(row.testerIdx)}
                          />
                        </TableCell>
                        <TableCell align="center" padding="none">
                          <Button
                            onClick={() => handleCopyClipBoard(row)}
                            size="small"
                            variant="outlined"
                            sx={{ "&": { maxWidth: 40, minWidth: 40 } }}
                          >
                            복사
                          </Button>
                        </TableCell>
                        <TableCell padding="none" align="center" component="th" scope="row">
                          {row.testerName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.testerBirth}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.testerCell}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          {YNColor(row.preExamYN)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {YNColor(row.foreignLoginYN)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {YNColor(row.attendanceYN)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {roomTesterListTimeFormat(row.logAt)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.normName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          <Stack direction={"row"} columnGap={1} justifyContent="center">
                            <Box position={"relative"}>
                              {examStatusTag(row.examStatus)}
                              {row.giveUpYN === "Y" && (
                                <Box position={"absolute"} top={2.5} right={-20}>
                                  <Tooltip title="응시포기" arrow placement="top">
                                    <ErrorOutlineRoundedIcon color="error" />
                                  </Tooltip>
                                </Box>
                              )}
                            </Box>
                          </Stack>
                        </TableCell>

                        <TableCell padding="none" align="center">
                          <MenuComponent list={moreList(row)} icon={<MoreHorizIcon />} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container alignItems={"center"} justifyContent="center">
              <Pagination
                size="small"
                count={pageInfo.totalPage}
                page={pageInfo.pageNo}
                onChange={(_, page) => {
                  setParamValues((prev) => ({
                    ...prev,
                    pageNo: page,
                  }));
                }}
              />
            </Grid>
          </Paper>
        </>
      )}
      {/* 응시자 정보수정 */}
      <Dialog open={updateRoomTesterModalOn} onClose={handleUpdateRoomTesterModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateRoomTesterModal roomTesterInfo={roomTesterInfo} onClose={handleUpdateRoomTesterModalOn} />
        </Suspense>
      </Dialog>
      {/* 응시자 진행상태 정보 수정 */}
      <Dialog open={updateTestStatusModal} onClose={handleUpdateTestStatusModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateTestStatusModal roomTesterInfo={roomTesterInfo} onClose={handleUpdateTestStatusModalOn} />
        </Suspense>
      </Dialog>
      {/* 응시자 접속로그 데이터 모달 */}
      <Dialog open={logDataModal} onClose={handleLogDataModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterLogDataModal roomTesterInfo={roomTesterInfo} onClose={handleLogDataModalOn} />
        </Suspense>
      </Dialog>
      {/* 신분증 확인 모달 */}
      <Dialog open={idCardCheckModal} onClose={handleIdCardCheckModalOn} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingCircle />}>
          <Idcardcheckmodal roomTesterInfo={roomTesterInfo} onClose={handleIdCardCheckModalOn} />
        </Suspense>
      </Dialog>
      {/* 채팅로그 모달 */}
      <Dialog open={testerChatLogModal} onClose={handleTesterChatLogModalOn} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterChatLogModal roomTesterInfo={roomTesterInfo} onClose={handleTesterChatLogModalOn} />
        </Suspense>
      </Dialog>
      {/* 비디오로그 모달 */}
      <Dialog open={testerVideoLogModal} onClose={handleTesterVideoLogModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterVideoLogModal roomTesterInfo={roomTesterInfo} onClose={handleTesterVideoLogModal} />
        </Suspense>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={copyResult}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={isCopyError ? "error" : "success"} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>{copyResultMessage}</Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
}
