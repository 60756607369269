import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { ReactComponent as Upload } from "assets/icon/uploadIcon.svg";
import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { modalAtom } from "recoil/store";
import { useRecoilState } from "recoil";
import { MESSAGE_UPLOAD } from "enums/modalMessage";
import useMutationHook from "hooks/useMutationHook";
import { useCallback } from "react";
import Swal from "sweetalert2";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";

const ExcelUploadModal = ({ data, onClose }) => {
  const [fileInfo, setFileInfo] = useState();
  const queryClient = useQueryClient();

  //* 문항일괄 업로드
  const { mutate: upload_TESTER } = useMutationHook(
    "uploadQuestions",
    "SUPER",
    false
  );

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    Swal.fire(
      alertModalConfig({
        text: "문항 등록이 완료되었습니다.",
        title: "문항 일괄등록",
        iconHtml: checkIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        queryClient.invalidateQueries({
          queryKey: [key],
        });
        onClose();
      }
    });
  }, []);

  const handleUpload = (e) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPLOAD,
        title: "문항 파일 업로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        upload_TESTER(
          {
            questionIdx: data.questionIdx,
            questionExcels: e.target.files[0],
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 일괄등록이 완료되었습니다.",
                  title: "문항 파일 업로드",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  setFileInfo(e.target.files[0].name);
                  alertResult("questionDetail2");
                }
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: error.response.data.message,
                  title: "문항 파일 업로드",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  return (
    <Box>
      <DialogTitle>문항 엑셀 업로드</DialogTitle>
      <DialogContent>
        <Card
          sx={{
            bgcolor: "#FFF4F4",
            border: "1px solid #fcd5d5",
            mb: 1,
            minHeight: 70,
          }}
          elevation={0}
        >
          {fileInfo && fileInfo !== "" ? (
            <Grid
              container
              alignItems={"center"}
              height={70}
              p={3}
              justifyContent="space-between"
              flexWrap={"nowrap"}
              width={"100%"}
            >
              <Grid item xs={10}>
                <Typography fontWeight={700}>{fileInfo}</Typography>
              </Grid>
              <Grid
                item
                xs
                container
                alignItems={"center"}
                columnGap={1}
                justifyContent="flex-end"
              >
                <CheckCircleOutlineRoundedIcon color="success" />
                <Typography fontWeight={700}>완료</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              p={4}
              container
              justifyContent="center"
              flexDirection={"column"}
              rowGap={1}
              alignItems="center"
            >
              <Upload />
              <Typography
                color={"#777"}
                textAlign="center"
                sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}
              >
                {`파일을 직접 업로드하거나 \n드래그하여 업로드 해주세요 (최대 10MB)`}
              </Typography>
              <Button variant="outlined" size="small" component="label">
                업로드
                <input
                  hidden
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  type="file"
                  onChange={handleUpload}
                />
              </Button>
            </Grid>
          )}
        </Card>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </Box>
  );
};

export default ExcelUploadModal;
