/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { getTextColorByBackgroundColor } from "./color";

export const LoginTemplate = ({
  layoutNumInput,
  colors,
  title,
  bg,
  isLogoUse,
  ciFilePath,
  bgImageUseYN,
}) => {
  const theme = {
    layoutIdx: 1,
    ciUseYN: isLogoUse,
    ciFilePath: ciFilePath,
    mainColor: colors.mainColor,
    subColor: colors.subColor,
    buttonColor: colors.buttonColor,
    frameColor: `rgba(${colors.frameColor.r},${colors.frameColor.g},${colors.frameColor.b},${colors.frameColor.a});`,
    titleText: title,
    titleColor: colors.titleColor,
    bgImageUseYN: bgImageUseYN,
    bgColor: bgImageUseYN === "N" && bg,
    bgImage: bg,
    fontColor: colors.titleColor,
  };
  const companyCode = true;
  const layoutNum = layoutNumInput;
  const fontColor = getTextColorByBackgroundColor(theme.buttonColor);

  return (
    <Wrapper theme={theme} companyCode={companyCode}>
      {layoutNum === "1" ||
      layoutNum === "3" ||
      layoutNum === "5" ||
      layoutNum === "6" ? null : (
        <LogoOut theme={theme} companyCode={companyCode} />
      )}

      <LoginBox layoutNum={layoutNum} theme={theme} companyCode={companyCode}>
        {layoutNum === "2" ||
        layoutNum === "4" ||
        layoutNum === "7" ||
        layoutNum === "8" ? null : companyCode && theme.ciUseYN === "Y" ? (
          <LogoIn theme={theme} companyCode={companyCode} />
        ) : null}

        <Title theme={theme} companyCode={companyCode}>
          {theme.titleText && (
            <div style={{ fontSize: "24px" }}>
              {companyCode ? theme.titleText : "인적성 검사"}
            </div>
          )}
        </Title>
        <InputWrap>
          <input type="text" placeholder="휴대전화번호 입력" />
        </InputWrap>

        <Button theme={theme} fontColor={fontColor} companyCode={companyCode}>
          인증번호 발송
        </Button>
      </LoginBox>
    </Wrapper>
  );
};

const Wrapper = styled.div((props) => ({
  width: "100vw",
  height: "100%",
  position: "relative",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  background: `
    ${
      props.companyCode
        ? props.theme.bgImageUseYN === "N" && props.theme.bgColor
        : null
    }`,
  backgroundImage: `${
    props.companyCode
      ? props.theme.bgImageUseYN === "Y" && `url(${props.theme.bgImage})`
      : `${props.theme.bgColor}`
  }`,
}));

const LogoOut = styled.div((props) => ({
  position: "relative",
  zIndex: "99",
  padding: "20px",
  width: "200px",
  height: "60px",
  marginLeft: "20px",
  backgroundPosition: "left",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `${
    props.companyCode &&
    props.theme.ciUseYN === "Y" &&
    `url(${props.theme.ciFilePath})`
  }`,
}));

const LogoIn = styled.div((props) => ({
  margin: "20px",
  width: "200px",
  height: "60px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundImage: `${
    props.companyCode &&
    props.theme.ciUseYN === "Y" &&
    `url(${props.theme.ciFilePath})`
  }`,
}));

const LoginBox = styled.form((props) => ({
  position: "absolute",
  top: "0",
  bottom: "0",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  width: "400px",
  height: props.layoutNum === "1" || props.layoutNum === "2" ? "420px" : "100%",
  backgroundColor: `${props.companyCode ? props.theme.frameColor : "white"}`,
  borderRadius:
    props.layoutNum === "1" || props.layoutNum === "2" ? "10px" : "0px",
  left:
    props.layoutNum === "1" ||
    props.layoutNum === "2" ||
    props.layoutNum === "3" ||
    props.layoutNum === "4" ||
    props.layoutNum === "5" ||
    props.layoutNum === "7"
      ? 0
      : "",
  right:
    props.layoutNum === "1" ||
    props.layoutNum === "2" ||
    props.layoutNum === "3" ||
    props.layoutNum === "4" ||
    props.layoutNum === "6" ||
    props.layoutNum === "8"
      ? 0
      : "",
}));

const Title = styled.div((props) => ({
  fontSize: "24px",
  padding: "20px",
  textAlign: "center",
  color: `${props.companyCode ? props.theme.titleColor : "#000"}`,
  height: "auto",
}));

const InputWrap = styled.div`
  input {
    width: 280px;
    height: 40px;
    border-radius: 8px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #999;
  }
`;

const Button = styled.button((props) => ({
  width: "280px",
  height: "40px",
  borderRadius: "8px",
  backgroundColor: `${props.companyCode ? props.theme.buttonColor : "#e9e9e9"}`,
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "20px",
  color: `${props.companyCode ? props.fontColor : "#000"}`,
}));
