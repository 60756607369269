import { Grid } from "@mui/material";
import React from "react";

const ExListNumberBtn = ({ index, onClick, ex }) => {
  return (
    <Grid
      onClick={onClick}
      borderRadius={"50%"}
      border="1px solid black"
      width={20}
      height={20}
      container
      justifyContent="center"
      alignItems="center"
      bgcolor={ex?.answerScore >= 1 && "black"}
      color={ex?.answerScore >= 1 && "white"}
      sx={{
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 0 0 0.2rem rgb(92 92 92/50%);",
          // background: "black",
          // color: "white",
        },
        "&:active": {
          transform: "scale(0.9)",
        },
      }}
    >
      {index + 1}
    </Grid>
  );
};

export default ExListNumberBtn;
