import React, {
  Suspense,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import {
  modalAtom,
  questionPageDetailAtom,
  questionsDetailAtom,
} from "recoil/store";
import { useRecoilState, useRecoilValue } from "recoil";

import useMutationHook from "hooks/useMutationHook";
import { useQueryClient } from "@tanstack/react-query";

import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { practiceType } from "utils/practiceType";
import EditQuestionPersonal from "./EditQuestionPersonal";
import NoneInfoByPage from "components/pages/acg_admin/practice/none/NoneInfoByPage";
import Swal from "sweetalert2";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import {
  alertModalConfig,
  confirmModalConfig,
} from "components/templates/modalConfig";

const QuestionPersonal = ({ tabId }) => {
  const question1st = useRecoilValue(questionsDetailAtom);
  const question2nd = useRecoilValue(questionPageDetailAtom);

  const [isEditMode, setIsEditMode] = useState(false);
  const [targetIndex, setTargetIndex] = useState(0);
  const [questionExList, setQuestionExList] = useState([]);
  const handleEditExList = () => setIsEditMode(!isEditMode);
  const exListRef = useRef([]);
  const { mutate } = useMutationHook("updateQuestionDetail");
  const queryClient = useQueryClient();

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  useEffect(() => {
    question2nd !== null &&
      setQuestionExList(question2nd?.questionList[0]?.questionExList);
  }, [question2nd]);

  const handleUpdatePersonalPracticeModal = (_, index) => {
    setTargetIndex(index);
    setUpdatePersonalPracticeModalOn(!updatePersonalPracticeModalOn);
  };

  const [updatePersonalPracticeModalOn, setUpdatePersonalPracticeModalOn] =
    useState(false);

  const updateExList = () => {
    const submitValues = {
      likertYN: question1st.likertYN,
      ipsativeYN: question1st.ipsativeYN,
      maxScale: question1st.maxScale,
      questionSetCnt: question1st.questionSetCnt,
      pageNo: 1,
      questionExRegistList: exListRef.current.map((item, index) => {
        return {
          questionExText: item.value,
          questionExNo: index + 1,
          answerScore: 0,
        };
      }),
      questionTitleRegistInfo: {
        questionSubText: question2nd.questionList[0].questionSubText,
        questionNo: question2nd.questionList[0].questionNo,
        questionText: question2nd.questionList[0].questionText,
        questionSubUseYN: question2nd.questionList[0].questionSubUseYN,
        questionExLayout: question2nd.questionList[0].questionExLayout,
      },
    };

    Swal.fire(
      confirmModalConfig({
        text: "응답척도를 수정하시겠습니까?",
        title: "응답척도 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            questionIdx: question2nd.questionList[0].questionIdx,
            values: submitValues,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응답척도 수정이 완료되었습니다.",
                  title: "응답척도 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("questionDetail2");
              });
            },
          }
        );
      }
    });
  };

  //* 예제 문항 삭제 Mutation
  const { mutate: deleteOneQuestion } = useMutationHook("deleteOneQuestion");

  const handleDeleteOnePracticeModal = (_, practice) => {
    Swal.fire(
      confirmModalConfig({
        text: "해당 문항을 삭제하시겠습니까?",
        title: "문항 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteOneQuestion(
          {
            questionIdx: practice.questionIdx,
            questionNo: practice.questionNo,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 삭제가 완료되었습니다.",
                  title: "문항 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("questionDetail2");
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: "문항 삭제를 실패하였습니다.",
                  title: "문항 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  if (question2nd && question2nd.questionList.length < 1)
    return <NoneInfoByPage />;

  return (
    <Grid container flexDirection="column" rowGap={2}>
      {(question1st && practiceType(question1st) === "TYPE3") ||
      tabId > 0 ? null : (
        <Grid container alignItems={"center"} columnGap={2}>
          <Grid
            item
            xs={8}
            component="fieldset"
            borderRadius={1}
            px={2}
            py={0}
            pb={0.8}
          >
            <legend>
              <Typography fontWeight={700}>응답척도</Typography>
            </legend>
            <Grid
              container
              alignItems="center"
              columnGap={1}
              justifyContent="space-around"
            >
              {questionExList?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {index !== 0 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ borderWidth: 1, m: 0 }}
                      />
                    )}
                    {isEditMode ? (
                      <TextField
                        size="small"
                        placeholder="하위 문항번호"
                        name="questionSubText"
                        defaultValue={item.questionExText}
                        inputRef={(el) => (exListRef.current[index] = el)}
                        sx={{
                          width: 100,
                          "&>div>input": { p: 0.6, textAlign: "center" },
                        }}
                      />
                    ) : (
                      <Typography key={index} textAlign="center">
                        {item.questionExText}
                      </Typography>
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
          {!isEditMode && (
            <Tooltip title="응답척도 수정" arrow placement="top">
              <Box component="span">
                <IconButton
                  onClick={handleEditExList}
                  size="small"
                  sx={{
                    background: "#fff2b9",
                    color: "#ffd000",
                    "&>svg": {
                      width: "1.2rem",
                      height: "1.2rem",
                    },
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
          {isEditMode && (
            <>
              <IconButton
                onClick={updateExList}
                size="small"
                sx={{
                  background: "#59e192",
                  color: "#daf8d1",
                  "&>svg": {
                    width: "1.2rem",
                    height: "1.2rem",
                  },
                }}
              >
                <CheckRoundedIcon />
              </IconButton>
              <IconButton
                onClick={handleEditExList}
                size="small"
                sx={{
                  background: "#ffafaf",
                  color: "#ff4444",
                  "&>svg": {
                    width: "1.2rem",
                    height: "1.2rem",
                  },
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </>
          )}
        </Grid>
      )}
      <Grid overflow="auto" container direction={"column"} borderRadius={1}>
        {question2nd &&
          question2nd.questionList?.map((practice, index) => {
            return (
              <Grid
                key={index}
                p={1}
                sx={{
                  "&:nth-of-type(n+1):nth-of-type(-n+3)": {
                    background: "#f2f2f2",
                    "&:hover": {
                      transition: "all 0.6s ease",
                      background: "#dbdbdb",
                    },
                  },
                  "&:hover": {
                    transition: "all 0.6s ease",
                    background: "#e4e4e4",
                    "&>div>div>div>p": {
                      fontWeight: 700,
                    },
                    ".optionBtn>div": {
                      transition: "all 0.7s ease",
                      ml: 2,
                    },
                    ".optionBtn>div>button, .optionBtn>div>span>button": {
                      transition: "all 0.7s ease",
                      opacity: 1,
                    },
                  },
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  pr={2}
                  rowGap={1}
                >
                  <Grid item container alignItems="center">
                    <Box>
                      {practiceType(question1st) === "TYPE2" ? (
                        <Typography>{`${practice.questionNo}. ${practice.questionText}`}</Typography>
                      ) : (
                        <Typography>{`${practice.questionNo}. ${practice.questionSubText}. ${practice.questionText}`}</Typography>
                      )}
                    </Box>

                    <Grid className="optionBtn">
                      <Grid item container columnGap={1}>
                        <Tooltip title="문항 수정" arrow placement="top">
                          <Box component="span">
                            <IconButton
                              onClick={(e) =>
                                handleUpdatePersonalPracticeModal(e, practice)
                              }
                              size="small"
                              variant="contained"
                              sx={{
                                background: "#ffd20a",
                                color: "white",
                                opacity: 0,
                                "&>svg": {
                                  width: "1.2rem",
                                  height: "1.2rem",
                                },
                              }}
                            >
                              <BorderColorRoundedIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Tooltip>

                        <Tooltip title="문항 삭제" arrow placement="top">
                          <Box component="span">
                            <IconButton
                              onClick={(e) =>
                                handleDeleteOnePracticeModal(e, practice)
                              }
                              size="small"
                              variant="contained"
                              disabled={
                                question2nd.questionList.length > 1 &&
                                index === 0
                              }
                              sx={{
                                background: "#ffafaf",
                                color: "#ff4444",
                                opacity: 0,
                                "&>svg": {
                                  width: "1.2rem",
                                  height: "1.2rem",
                                },
                              }}
                            >
                              <ClearRoundedIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}

        <Dialog
          maxWidth="md"
          fullWidth
          open={updatePersonalPracticeModalOn}
          onClose={handleUpdatePersonalPracticeModal}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <EditQuestionPersonal
              targetIndex={targetIndex}
              pageNo={tabId + 1}
              onClose={handleUpdatePersonalPracticeModal}
            />
          </Suspense>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default QuestionPersonal;
